import React, { Dispatch, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState, actions } from '../store';
import Map from '../components/Map/Map';
import { LoginWithActivationKeyInfo } from '../webServer/controllers/commonWeb/types/type';
import TAXI_PARAMS from '../utils/build';
import { 
    IonButtons, 
    IonContent, 
    IonMenuButton, 
    IonPage 
} from '@ionic/react';
import './Page.scss';
import { IParametersDictionary } from 'sedi-webserverproxy';

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ name: string }>;

const Page: React.FC<Props> = (props) => {
    const { 
      loginWithActivationKeyAction,
      loginWithUserKeyAction
     } = props;
    let location = useLocation();
    const personalKey = location.hash.slice(1);

    useEffect(() => {
        if (location.hash.length > 0) {
          if(location.hash.length > 5){
            loginWithUserKeyAction({
                userKey: personalKey
            });
          }
          else {
            loginWithActivationKeyAction({
                userType: 'customer',
                licenceKey: TAXI_PARAMS.WEBSERVER_APIKEY,
                activationKey: personalKey,
            });
          }
        }
    }, [location.hash.length, loginWithActivationKeyAction, personalKey, loginWithUserKeyAction]);

  return (
    <IonPage>
      <IonButtons className="menuBurger" slot="start">
        <IonMenuButton />
      </IonButtons>

      <IonContent>
        <Map />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    loginWithActivationKeyAction: (parameter: LoginWithActivationKeyInfo) =>
        dispatch(actions.registration.loginWithActivationKeyAction(parameter)),
    loginWithUserKeyAction: (parameter: IParametersDictionary) =>
        dispatch(actions.registration.loginWithUserKeyAction(parameter)),    
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
