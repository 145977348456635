import { Reducer } from "redux";
import { RegistrationActionTypes, RegistrationState } from "./types";
import { RegistrationActions } from "./actions";

const defaultState: RegistrationState = {
    sendActivationKeyResult: null,
    activationKeyResult: JSON.parse(localStorage.getItem('activationKeyResult') || '{}'),
    error: null,
    activationKeyOpen: false,
    currentTariffOpen: false,
    calcCostProcessing: false,
    getRouteProcessing: false
};

export const addressCacheReducer: Reducer<RegistrationState, RegistrationActions> = (state = defaultState, action) => {
    switch (action.type) {
        case RegistrationActionTypes.CURRENT_TARIFF_OPEN:
            return {
                ...state,
                currentTariffOpen: action.currentTariffOpen
            };
        case RegistrationActionTypes.SEND_ACTIVATION_KEY:
            return {
                ...state,
                sendActivationKeyResult: action.result,
                activationKeyOpen: action.activationKeyOpen,
                error: null
            };
        case RegistrationActionTypes.ACTIVATION_KEY:
            localStorage.setItem('activationKeyResult', JSON.stringify(action.activationKeyResult));
            return {
                ...state,
                activationKeyResult: action.activationKeyResult,
                error: null
            };
        case RegistrationActionTypes.GET_ERROR:
            return {
                ...state,
                error: action.error
            };
        case RegistrationActionTypes.ACTIVATION_KEY_OPEN:
            return {
                ...state,
                activationKeyOpen: action.activationKeyOpen
            };
        case RegistrationActionTypes.CALC_COST_PROCESSING:
            return {
                ...state,
                calcCostProcessing: action.calcCostProcessing
            };
        case RegistrationActionTypes.GET_ROUTE_PROCESSING:
            return {
                ...state,
                getRouteProcessing: action.getRouteProcessing
            };
        default:
            return state;
    }
};

export default addressCacheReducer;