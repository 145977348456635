export interface ICalculateCost {
    basicTariffId: number;
    tariffDescription: string;
    calculatedDistance: number;
    cost: number;
    costDetails: string;
    costCalculationId: number;
    currency: string;
}


export class CalculateCost implements ICalculateCost {
    basicTariffId: number;
    tariffDescription: string;
    calculatedDistance: number;
    cost: number;
    costDetails: string;
    costCalculationId: number;
    currency: string;

    constructor(source?: ICalculateCost) {
        this.basicTariffId = source ? source.basicTariffId : 0;
        this.tariffDescription = source ? source.tariffDescription : '';
        this.calculatedDistance = source ? source.calculatedDistance : 0;
        this.cost = source ? source.cost : 0;
        this.costDetails = source ? source.costDetails : '';
        this.costCalculationId = source ? source.costCalculationId : 0;
        this.currency = source ? source.currency : '';
    }
}

export interface CalculateCostInfo {
    licenseKey: string,
    userKey?: string,
    orderTime?: Date,
    totalDuration?: number,
    totalDistance?: number,
    specialServices?: string[],
    isCashless?: true,
    routePoints?: RoutePoints[],
    keyWord?: string
}

export type RoutePoints = {
    latitude: number,
    longitude: number
}

export interface IGetRoutingInfo {
    routePoints: RoutePoints[],
    totalDuration: number,
    totalDistance: number,
    from: RoutePoints | null
    to: RoutePoints | null
}

export interface IRoutingParams {
    routePoints: RoutePoints[],
    orderTime: Date
}