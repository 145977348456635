export interface ISendActivationKey {
    phoneOrEmail: string,
    userType: UserType,
    licenceKey: string
}

export enum UserType {
    unknown = 'unknown',
    customer = 'customer',
    employee = 'employee',

}

export enum SendActivationKeyResult {
    newUser = 'newUser',
    phoneIsRegisteredForOneUser = 'phoneIsRegisteredForOneUser',
    phoneIsRegisteredForSeveralUsersOfOneUserType = 'phoneIsRegisteredForSeveralUsersOfOneUserType',
    phoneIsRegisteredForSeveralUsersOfDifferentUserType = 'phoneIsRegisteredForSeveralUsersOfDifferentUserType',
    mobilePhoneIncorrect = 'mobilePhoneIncorrect',
    unsupportedSendingWay = 'unsupportedSendingWay',
    error = 'error',
    emailHasIncorrectFormat = 'emailHasIncorrectFormat',
    emailIsRegisteredForSeveralUsersOfDifferentUserType = 'emailIsRegisteredForSeveralUsersOfDifferentUserType',
    userInBlackList = 'userInBlackList',
    userNotFound = 'userNotFound',
    directRegistrationIsNotAllowedInGroup = 'directRegistrationIsNotAllowedInGroup',
    employeeGroupNotFound = 'employeeGroupNotFound'
}

export interface LoginWithActivationKeyInfo {
    userType: string,
    licenceKey: string | undefined,
    activationKey: string,
}

export interface ILoginWithActivationKey {
    userType: string,
    licenceKey: string | undefined,
    activationKey: string,
}


export class LoginWithActivationKey implements ILoginWithActivationKey {
    userType: string;
    licenceKey: string | undefined;
    activationKey: string;

    constructor(source?: ILoginWithActivationKey) {
        this.userType = source ? source.userType : '';
        this.licenceKey = source ? source.licenceKey : '';
        this.activationKey = source ? source.activationKey : '';
    }
}
