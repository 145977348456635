import React, { useState } from 'react';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonItem,
  IonInput,
  IonButton,
} from '@ionic/react';
import './InfoModal.scss';
import { chevronBack } from 'ionicons/icons';
import { localStorageGetItem } from '../../utils/storage';
import { useTranslation } from 'react-i18next';

type Props = {
  infoOpen: boolean;
  handleInfoToggle: () => void;
};

const infoEn = [
  { title: 'Very hot! Please turn on the air conditioner.' },
  { title: 'I`m in a hurry! Please, as soon as possible!' },
  { title: 'Call me as soon as you get the car' },
  { title: 'There is a cargo. You need to have a free trunk.' },
];
const infoDe = [
  { title: 'Sehr heiß! Bitte schalten Sie die Klimaanlage ein.' },
  { title: 'Ich habe es eilig! Ich frage so schnell wie möglich!' },
  { title: 'Rufen Sie mich an, sobald Sie ein Auto hinzufügen' },
  { title: 'Es gibt eine Last. Du brauchst einen freien Kofferraum.' },
];
const infoRu = [
  { title: 'Очень жарко! Пожалуйст, включите кондиционер.' },
  { title: 'Очень спешу! Прошу как можно скорее!' },
  { title: 'Позвоните мне, как только подалите автомобиль' },
  { title: 'Есть груз. Нужно наличие свободного багажника.' },
];
const infoHe = [
  { title: 'חם לי מאוד בבקשה הפעל את המזגן בבקשה' },
  { title: 'אני ממהר, תזדרז בבקשה' },
  { title: 'תתקשר אלי ברגע שתגיע למיקום' },
  { title: 'מגיע עם מטען, צריך מקום עבורו' },
];

export const InfoModal: React.FC<Props> = ({ infoOpen, handleInfoToggle }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const handleInfoClick = (title: string) => {
    setValue(title);
  };

  const languageSelect = () => {
    let language;
    const currentlLng = "en";
    const lang = localStorageGetItem('i18nextLng');
    const lngSelect = lang ? lang : currentlLng;

    switch (lngSelect) {
      case 'he':
        language = infoHe.map((he, i) => (
          <IonItem key={'he-' + i} onClick={() => handleInfoClick(he.title)}>
            <p>{he.title}</p>
          </IonItem>
        ));
        return language;
        
      case 'ru':
        language = infoRu.map((ru, i) => (
          <IonItem key={'ru-' + i} onClick={() => handleInfoClick(ru.title)}>
            <p>{ru.title}</p>
          </IonItem>
        ));
        return language;

      case 'de':
        language = infoDe.map((de, i) => (
          <IonItem key={'de-' + i} onClick={() => handleInfoClick(de.title)}>
            <p>{de.title}</p>
          </IonItem>
        ));
        return language;

      default:
        language = infoEn.map((en, i) => (
          <IonItem key={'en-' + i} onClick={() => handleInfoClick(en.title)}>
            <p>{en.title}</p>
          </IonItem>
        ));
        return language;
    }
  };

  return (
    <IonModal isOpen={infoOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('additionalInformation')}</IonTitle>

          <IonButtons slot="start" onClick={() => handleInfoToggle()}>
            <IonIcon icon={chevronBack} size="large" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="infoModal">
        <IonItem>
          <IonInput
            value={value}
            placeholder={t('additionalInformation')}
            onIonChange={(e) => setValue(e.detail.value!)}
          />
        </IonItem>

        {languageSelect()}

        <div className="buttonRow">
          <IonButton className="button" color="primary" onClick={handleInfoToggle}>
            {t('save')}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default InfoModal;
