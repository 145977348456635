import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import WebServerApi from '../api/WebServerApi';
import { 
    IonModal, 
    IonContent, 
    IonHeader, 
    IonButtons, 
    IonTitle, 
    IonToolbar, 
    IonIcon, 
    IonFooter, 
    IonButton, 
    IonList, 
    IonItem, 
    IonLabel, 
    IonInput, 
    IonSpinner 
} from '@ionic/react';
import './TariffsModal.scss';
import { IGetAddressItem } from 'sedi-webserverproxy';

type Props = ReturnType<typeof mapDispatchToProps> & 
    ReturnType<typeof mapStateToProps> & {
        showModal: boolean,
        openModal: () => void,
        tariffTitle: string,
        basicTariffId: string,
        tariffCost: number
    }

export const TariffsModal: React.FC<Props> = (props) => {
    const { 
        showModal, 
        openModal, 
        tariffTitle, 
        activationKeyResult, 
        route, 
        basicTariffId, 
        successAddOrderAction, 
        lang,
        tariffCost
    } = props;
    const { t } = useTranslation();
    const webServerApi = new WebServerApi();
    const [value, setValue] = useState(activationKeyResult?.userPhone);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const routePoint = (point: IGetAddressItem | null) => {
        if (point) {
            if (point.t === "c") {
                return point.v;
            } else if (point.t === "s") {
                return point.v;
            } else if (point.t === "o") {
                return point.v;
            } else {
                return `${point.s}${point.v !== "" ? ", " : ""}${point.v}`;
            }
        }
    };

    const saveClick = () => {
        if(activationKeyResult && route.point1 && route.point2) {
            setLoading(true);
            webServerApi.addOrder(
                activationKeyResult.userKey, 
                activationKeyResult.userPhone, 
                lang, 
                basicTariffId, 
                tariffCost,
                route.point1.c, 
                route.point1.s, 
                (route.point1.t === "h" ? route.point1.v : (route.point1.h ? route.point1.h : '')),
                (route.point1.t === "o" ? route.point1.v : ''), 
                route.point1.g.lat, route.point1.g.lon,
                route.point2.c, 
                route.point2.s, 
                (route.point2.t === "h" ? route.point2.v : (route.point2.h ? route.point2.h : '')), 
                (route.point2.t === "o" ? route.point2.v : ''), 
                route.point2.g.lat, route.point2.g.lon
            ).then((data: any) => {
                setLoading(false);
                setMessage(data.Message);
                successAddOrderAction(data.Success);
            });
        }
    }

    const clearClick = () => {
        openModal();
        setMessage('');
    }

    return (
        <IonModal isOpen={showModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{tariffTitle}</IonTitle>
                    <IonButtons 
                        slot="start" onClick={() => openModal()}>
                        <IonIcon icon={chevronBack} size="large" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="tariffContent">
                <div className="tariffContent__item">
                    <span className="inputIcon">A</span>
                    <IonLabel>{routePoint(route.point1)}</IonLabel>
                </div>
                {route.point2 && 
                    <div className="tariffContent__item">
                        <span className="inputIcon">B</span>
                        <IonLabel>{routePoint(route.point2)}</IonLabel>
                    </div>
                }
                <IonList lines="full" className="tariffContent__phone">
                    <IonItem>
                        <IonLabel  
                            position="stacked">
                            {t('tariff.phone')}
                        </IonLabel>
                        <IonInput
                            required type="text" 
                            value={value ? value : activationKeyResult!.userPhone || ''} 
                            onIonChange={e => setValue(e.detail.value!)} 
                        />
                    </IonItem>
                </IonList>
                <div className="tariffContent__message">
                    {loading &&  <IonSpinner name="lines" color="primary" />}
                    {message && 
                        <div className="messageSuccess">
                            <p>{message}</p>
                            <IonButton color="success" onClick={clearClick}>Ок</IonButton>
                        </div>
                    }
                </div>
            </IonContent>
            <IonFooter className="servicesFooter">
                <IonButton color="danger" onClick={clearClick}>{t('cancel')}</IonButton>
                <IonButton onClick={saveClick}>{t('order')}</IonButton>
            </IonFooter>
        </IonModal>
    );
};

const mapStateToProps = (state: RootState) => ({
    tariffTitle: state.map.tariffTitle,
    valueFromView: state.map.valueFromView,
    activationKeyResult: state.registration.activationKeyResult,
    route: state.map.route,
    lang: state.map.lang
});
  
const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    successAddOrderAction: (successAddOrder: boolean) => dispatch(actions.map.successAddOrderAction(successAddOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsModal);