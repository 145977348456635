import { IonAlert } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

type Props = {
    regAlertOpen: boolean;
    regAlertCloseClick: () => void;
}

const RegistrationAlert:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    let history = useHistory();
    const {
        regAlertOpen,
        regAlertCloseClick 
    } = props;
    return (
        <IonAlert
            isOpen={regAlertOpen}
            onDidDismiss={regAlertCloseClick}
            header={t('needToRegister')}
            message={t('wantToRegister')}
            buttons={[
                {
                    text: t('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => regAlertCloseClick(),
                },
                {
                    text: 'Ok',
                    handler: () => history.push('/authorization'),
                }
            ]}
        />
    );
}

export default RegistrationAlert;