import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import './Authorization.scss'
import ActivationKeyModal from '../ActivationKeyModal/ActivationKeyModal';
import { localStorageSetItem } from '../../utils/storage';
import { useHistory } from 'react-router';
import { ISendActivationKey, UserType } from "../../webServer/controllers/commonWeb/types/type";
import TAXI_PARAMS from '../../utils/build';
import { useTranslation } from 'react-i18next';
import { 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonIcon, 
    IonButtons, 
    IonContent, 
    IonItem, 
    IonLabel, 
    IonInput, 
    IonPage, 
    IonList, 
    IonButton, 
    IonText 
} from '@ionic/react';
import { 
    chevronBackOutline, 
    mailOutline 
} from 'ionicons/icons';

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

const Authorization:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    let history = useHistory();
    const { 
        sendActivationKeyAction,
        error,
        activationKeyOpen,
        activationKeyOpenAction,
    } = props;
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
  
    const keyModalOpen = () => {
        localStorageSetItem('name', name);
        const parametr = {
            phoneOrEmail: '+' + phone.replace('+', ''),
            userType: UserType.customer,
            licenceKey: TAXI_PARAMS.WEBSERVER_APIKEY!
        }
        sendActivationKeyAction(parametr);
    }

    const keyModalClose = () => {
        activationKeyOpenAction(false);
    }
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons 
                        slot="start">
                        <IonIcon 
                            icon={chevronBackOutline} 
                            size="large" 
                            onClick={() => history.goBack()} />
                    </IonButtons>
                    <IonTitle>{t('authorization')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="authorization">
                <IonList>
                    <IonLabel 
                        className="authorization__title">
                        {t('enterPhoneNumber')}
                    </IonLabel>
                    <IonItem className="authorization__item">
                        <IonLabel 
                            position="floating">{t('phoneNumber')}*</IonLabel>
                        <IonInput 
                            value={phone} 
                            onIonChange={e => setPhone(e.detail.value!)} 
                            clearInput
                            required={true}></IonInput>
                    </IonItem>
                    {error && 
                        <IonText color="danger" className="errorMessage">{error}</IonText>
                    }
                    <IonItem className="authorization__item">
                        <IonLabel position="floating">{t('yourName')}</IonLabel>
                        <IonInput value={name} onIonChange={e => setName(e.detail.value!)} clearInput></IonInput>
                    </IonItem>
                    <div className="authorization__btnRow">
                        <IonButton onClick={keyModalOpen}>
                            <IonIcon slot="start" icon={mailOutline} />
                            {t('getSMS')}
                        </IonButton>
                    </div>
                </IonList>
            </IonContent>
            <ActivationKeyModal 
                keyModal={activationKeyOpen}
                keyModalClose={keyModalClose} />
        </IonPage>
    )
}

const mapStateToProps = (state: RootState) => ({
    error: state.registration.error,
    activationKeyOpen: state.registration.activationKeyOpen
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    sendActivationKeyAction: (parameter: ISendActivationKey) =>
        dispatch(actions.registration.sendActivationKeyAction(parameter)),
    activationKeyOpenAction: (activationKeyOpen: boolean) => 
        dispatch(actions.registration.activationKeyOpenAction(activationKeyOpen)),
});
  
export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(Authorization);