import { Reducer } from "redux";
import { MapActionTypes, MapState } from "./types";
import { MapActions } from "./actions";
import TAXI_PARAMS from "../../utils/build";

const defaultState: MapState = {
    route: {
        point1: null,
        point2: null
    },
    currentAddress: null,
    addressInfo: [],
    routingInfo: null,
    selectedPoint: 1,
    center: {
        lat: Number(TAXI_PARAMS.LAT || 55.755825),
        lon: Number(TAXI_PARAMS.LNG || 37.617298),
    },
    cost: [],
    tariffTitle: '',
    services: [],
    footerHeight: 0,
    valueFromView: '',
    changePointAutocomplate: false,
    successAddOrder: false,
    origin: null,
    destination: null,
    routeReset: false,
    serviceIsChecked: [],
    lang: '',
    carInWay: false,
    addressMethod: "",
};

export const mapReducer: Reducer<MapState, MapActions> = (state = defaultState, action) => {
    switch (action.type) {
        case MapActionTypes.ADDRESS_METOD:
            return {
                ...state,
                addressMethod: action.addressMethod
            };
        case MapActionTypes.ADDRESS_INFO:
            return {
                ...state,
                addressInfo: action.addressInfo
            };
        case MapActionTypes.GET_ROUTING_INFO:
            return {
                ...state,
                routingInfo: action.routingInfo
            };
        case MapActionTypes.CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.currentAddress
            };
        case MapActionTypes.CAR_IN_WAY:
            return {
                ...state,
                carInWay: action.carInWay
            };
        case MapActionTypes.LANG:
            return {
                ...state,
                lang: action.lang
            };
        case MapActionTypes.SERVICE_IS_CHECKED:
            return {
                ...state,
                serviceIsChecked: action.serviceIsChecked
            };
        case MapActionTypes.ROUTE_RESET:
            return {
                ...state,
                routeReset: action.routeReset
            };
        case MapActionTypes.GET_DESTINATION:
            return {
                ...state,
                destination: action.destination
            };
        case MapActionTypes.GET_ORIGN:
            return {
                ...state,
                origin: action.origin
            };
        case MapActionTypes.SUCCES_ADD_ORDER:
            return {
                ...state,
                successAddOrder: action.successAddOrder
            };
        case MapActionTypes.SET_POINT_AUTOCOMPLATE:
            return {
                ...state,
                changePointAutocomplate: action.changePointAutocomplate
            };
        case MapActionTypes.GET_ROUTE:
            return {
                ...state,
                route: action.route
            };
        case MapActionTypes.SET_ROUTE_POINT:
            const newRoute = state.route;
            if (state.selectedPoint === 1) {
                newRoute.point1 = action.routePoint
            }
            if (state.selectedPoint === 2) {
                newRoute.point2 = action.routePoint
            }
            return {
                ...state,
                route: newRoute,
            };
        case MapActionTypes.GET_SELECTED_POINT:
            return {
                ...state,
                selectedPoint: action.selectedPoint
            };
        case MapActionTypes.ADDRESS_CENTER:
            return {
                ...state,
                center: action.center
            };
        case MapActionTypes.CALCULATE_COST:
            return {
                ...state,
                cost: action.cost
            };
        case MapActionTypes.TARIFF_TITLE:
            return {
                ...state,
                tariffTitle: action.tariffTitle
            };
        case MapActionTypes.GET_SERVICES:
            return {
                ...state,
                services: action.services
            };
        case MapActionTypes.GET_FOOTER_HEIGHT:
            return {
                ...state,
                footerHeight: action.footerHeight
            };
        case MapActionTypes.GET_VALUE_FROM_VIEW:
            return {
                ...state,
                valueFromView: action.valueFromView
            };
        case MapActionTypes.SET_ROUTE_POINTS:
            return {
                ...state,
                routePoints: action.points
            };
        default:
            return state;
    }
};

export default mapReducer;