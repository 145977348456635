import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autosuggest, {
    ChangeEvent, 
    SuggestionSelectedEventData,
    RenderSuggestionParams, 
    SuggestionsFetchRequestedParams, 
    Theme
} from 'react-autosuggest';
import {  
    AutocompleteHelperOptions,
    AddressAutocomplete,
    AddressAPI,
    IGetAddressItem,
    AddressCenter,
} from 'sedi-webserverproxy';
import TAXI_PARAMS from '../../utils/build';


type Config = {
  lat?: number,
  lon?: number,
  radius?: number,
  regionPlusIds?: number[],
  countryName?: string,
}


type Props = AutocompleteHelperOptions & Config 
    & ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
    value?: string,
    onSelect?: (address: IGetAddressItem) => void,
    onChangeInput?: (newValue: string) => void,
    placeholder?: string
    inputClassName?: string,
    inputClassNameLoading?: string,
    errorClassName?: string,
    theme?: Theme,
    maxDisplayCount?: number,
    requestDelay?: number,
    debug?: boolean,
    getConfig?: () => Config,
    externalAddress?: IGetAddressItem,
    cityClassName?: string,
    handleSearchToggle?: () => void;
    addressItems?: IGetAddressItem[];
};

type DefaultProps = {
    requestDelay: number,
    minStringLengthForSearch: number
}


type State = {
  isLoading: boolean,
  value: string,
  errorText: string,
  suggestions: IGetAddressItem[],
  selectedSuggestion?: IGetAddressItem,
  autoFocus: boolean,
}


class WidgetAutocomplete extends Component<Props, State> {

  static defaultProps: DefaultProps = {
    requestDelay: 700,
    minStringLengthForSearch: 3
  }

  private timeoutID: number = -1;
  private helperNew: AddressAutocomplete;


  constructor(props: Props) {
    super(props);
    
    // Для инициализации url
    AddressAPI.initialization(`${TAXI_PARAMS.SERVER_API_URL}:12013`, 
      TAXI_PARAMS.ADD_AUTOCOMPLETE_LICENCEKEY === "true" ? TAXI_PARAMS.WEBSERVER_APIKEY : undefined);
    this.helperNew = new AddressAutocomplete(new AddressAPI(), props);

    this.state = {
      isLoading: false,
      value: this.props.value || "",
      errorText: '',
      suggestions: [],
      selectedSuggestion: undefined,
      autoFocus: false
    };

    this.handleClear = this.handleClear.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);

    this._setConfigToHelper(props);
  }

    componentDidMount() {
        // const { externalAddress, selectedPoint } = this.props;
        // if(selectedPoint === 1) {
        //     if (externalAddress !== undefined) {
        //         this.helperNew.SetSelectedAddress(externalAddress);
        //         const addressString = this.helperNew.GetCurrentAddressString();
        
        //         this.setState({
        //             value: addressString,
        //             suggestions: [externalAddress],
        //             selectedSuggestion: externalAddress
        //         });
        //     }
        //     else {
        //         this.setState({ value: '' });
        //         // сбрасываем без задержки
        //         this.setValueAndWaitSuggestions('');
        //     }
        // }
    }

  componentDidUpdate(prevProps: Props) {
    const { externalAddress, value, addressItems, addressInfoAction } = this.props;

    // if(selectedPoint !== prevProps.selectedPoint) {
    //     if(valueFromView === "") {
    //         this.setState({
    //             value: ""
    //         }); 
    //     }
    //     this.setState({
    //         value: valueFromView
    //     });
    //     console.log('valueFromView', valueFromView);
    //     console.log('this.state.value', this.state.value);
    // }

    if(value !== prevProps.value && value === "") {
        this.setState({
            value: ""
        });    
    }

    if (externalAddress !== prevProps.externalAddress) {
        if (externalAddress !== undefined) {
            this.helperNew.SetSelectedAddress(externalAddress);
            const addressString = this.helperNew.GetCurrentAddressString();

            this.setState({
                value: addressString,
                suggestions: [externalAddress],
                selectedSuggestion: externalAddress
            });

            addressInfoAction([externalAddress]);
        }
        else {
            this.setState({ value: '' });
            // сбрасываем без задержки
            this.setValueAndWaitSuggestions('');
        }
    }
    if(addressItems !== prevProps.addressItems) {
        if(addressItems !== undefined) {
            this.setState({
                suggestions: addressItems,
                autoFocus: true,
            });
            addressInfoAction(addressItems);
        }
    }
  }


  handleChangeValue(request: SuggestionsFetchRequestedParams) {
    // запрос с задержкой
    this.setValueAndWaitSuggestionsWithDelay(request.value);
  }


  handleClear() {
    this.setState({ suggestions: [] });
    this.props.addressInfoAction([]);
  }


  setValueAndWaitSuggestionsWithDelay(value: string) {
    const { requestDelay } = this.props;

    clearTimeout(this.timeoutID);

    this.timeoutID = window.setTimeout(() => {
      this.setValueAndWaitSuggestions(value);
    }, requestDelay);
  }


  private _setConfigToHelper(config?: Config) {
    if(config && (config.lat && config.lon)) {
        this.helperNew.SetCenter(config.lat, config.lon);
    }
    if(config && config.regionPlusIds) {
        this.helperNew.RegionPlusIds(config.regionPlusIds);
    }
    if(config && config.countryName) {
        this.helperNew.CountryName(config.countryName);
    }
  }


  async setValueAndWaitSuggestions(value: string) {
    const { getConfig, maxDisplayCount, lang } = this.props;

    this.setState({ isLoading: true, errorText: '' });

    try {
      if (getConfig) {
        const config = getConfig();
        this._setConfigToHelper(config);
      }

      const response = await this.helperNew.ChangeInputValue(value, lang);
      if (response.Success === false) {
        this.setState({
          errorText: response.Message || 'autocomplete undefined error',
          suggestions: []
        });
        this.props.addressInfoAction([]);
        return;
      }

      let suggestionsForDisplay = response && response.Result ? response.Result.items : [];

      if (maxDisplayCount !== undefined && maxDisplayCount > 0) {
        suggestionsForDisplay = suggestionsForDisplay.filter((s, index) => index < maxDisplayCount);
      }

      this.setState({ suggestions: suggestionsForDisplay });
      this.props.addressInfoAction(suggestionsForDisplay);
    }
    catch (ex: any) {
      this.setState({ errorText: ex.message });
    }
    finally {
      this.setState({ isLoading: false });
    }
  }


  handleSuggestionSelected(event: React.FormEvent<any>, data: SuggestionSelectedEventData<IGetAddressItem>) {
    const { 
        onSelect, 
        setRoutePointAction, 
        handleSearchToggle,
        addressCenterAction, 
        setPointAutocomplateAction,
        currentAddressAction
    } = this.props;
    const selectedSuggestion = data.suggestion;

    currentAddressAction(selectedSuggestion)

    this.helperNew.SetSelectedAddress(selectedSuggestion);
    const addressString = this.helperNew.GetCurrentAddressString();

    this.setState({ value: addressString, selectedSuggestion });

    // запрос домов после выбора улицы
    if (selectedSuggestion.t === 's') {
        // запрос немедленно
        this.setValueAndWaitSuggestions(addressString);
    }

    if(selectedSuggestion.t === "h" || selectedSuggestion.t === "o") {
        if(handleSearchToggle) {
            handleSearchToggle();
        }
        setRoutePointAction(selectedSuggestion);
        addressCenterAction({ 
            lat: selectedSuggestion.g.lat, 
            lon: selectedSuggestion.g.lon 
        });
        setPointAutocomplateAction(true);
        return;
    }
    onSelect && onSelect(selectedSuggestion);
  }


  render() {
    const { isLoading, value, errorText, suggestions, selectedSuggestion } = this.state;
    const {
      placeholder,
      inputClassName = '',
      inputClassNameLoading = '',
      theme,
      errorClassName,
      debug,
      onChangeInput,
      cityClassName,
    } = this.props;

    const inputProps = {
      className: isLoading ? inputClassNameLoading : inputClassName,
      placeholder,
      value,
      onChange: (event: React.FormEvent<any>, params: ChangeEvent) => {
        const newValue = params.newValue;
        this.setState({ value: newValue });
        onChangeInput && onChangeInput(newValue);
        this.props.addressMethodAction(params.method);
      },
      autoFocus: true
    };

    const suggestTheme = { ...getTheme(theme) };

    return (
      <>
        {debug &&
          <>
            <div>
              props: {JSON.stringify(this.props)}
              {isLoading && <span>Loading...</span>}
            </div>
            {selectedSuggestion && <div>Selected: {JSON.stringify(selectedSuggestion)}</div>}
          </>
        }

        <Autosuggest 
            theme={suggestTheme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.handleChangeValue}
            onSuggestionsClearRequested={this.handleClear}
            onSuggestionSelected={this.handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(suggestion: IGetAddressItem, params: RenderSuggestionParams) => 
                renderSuggestion(suggestion, params, cityClassName, debug)}
            inputProps={inputProps}
            alwaysRenderSuggestions={this.state.autoFocus}
        />

        {errorText &&
          <div className={errorClassName}>
            {errorText}
          </div>
        }
      </>
    );
  }
}


const getTheme = (userTheme?: Theme) => {
    const defaultTheme = {
        suggestionsContainer: 'searchBar__option',
        suggestionsList: 'searchBar__list',
        suggestion: 'searchBar__item',
    }
  const mergedTheme = Object.assign({}, defaultTheme, userTheme);
  return mergedTheme;
};


const renderSuggestion = (suggestion: IGetAddressItem, params: RenderSuggestionParams, cityClassName?: string, debug?: boolean) => {
    if(suggestion.t === "o") {
        return (
            <div>
                {debug &&
                    <span className="small font-weight-bold mr-1">{suggestion.t}</span>
                }
                <span>{suggestion.v} {(suggestion.s != null && suggestion.s !== "") && ', ' + suggestion.s} {(suggestion.h !== null && suggestion.h !== "") && ', ' + suggestion.h}</span>
                <div className={cityClassName}>
                    {suggestion.c}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                {debug &&
                    <span className="small font-weight-bold mr-1">{suggestion.t}</span>
                }
                <span>{suggestion.s && suggestion.s + ', '}{suggestion.v}</span>
                <div className={cityClassName}>
                    {suggestion.c}
                </div>
            </div>
        )
    }
};


const getSuggestionValue = (suggestion: IGetAddressItem) => {
  if (suggestion.c) {
    return suggestion.c + ', ' + suggestion.v;
  }

  return suggestion.v;
}

const mapStateToProps = (state: RootState) => ({
    selectedPoint: state.map.selectedPoint,
    valueFromView: state.map.valueFromView,
    lang: state.map.lang,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setRoutePointAction: (routePoint: IGetAddressItem) =>
        dispatch(actions.map.setRoutePointAction(routePoint)),
    setPointAutocomplateAction: (changePointAutocomplate: boolean) =>
        dispatch(actions.map.setPointAutocomplateAction(changePointAutocomplate)),
    addressCenterAction: (center: AddressCenter) =>
        dispatch(actions.map.addressCenterAction(center)),
    currentAddressAction: (currentAddress: IGetAddressItem) =>
        dispatch(actions.map.currentAddressAction(currentAddress)),
    addressInfoAction: (addressInfo: IGetAddressItem[]) =>
        dispatch(actions.map.addressInfoAction(addressInfo)),
    addressMethodAction: (addressMethod: string) =>
        dispatch(actions.map.addressMethodAction(addressMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetAutocomplete);;