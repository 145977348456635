import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import TAXI_PARAMS from '../utils/build';


const options = {
  // prefix for stored languages
  prefix: 'i18next_res_',

  // expiration
  expirationTime: 7*24*60*60*1000,

  // Version applied to all languages, can be overriden using the option `versions`
  defaultVersion: '',

  // language versions
  versions: {},

  // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
  store: window.localStorage,
}

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    lng: TAXI_PARAMS.DEFAULT_LANG,
    fallbackLng: TAXI_PARAMS.DEFAULT_LANG, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: true,
    whitelist: ['de', 'en', 'ru', 'he'],
    detection: options,

    interpolation: {
      escapeValue: false
    },
    backend: {
      backends: [
        LocalStorageBackend,  // primary
        XHR                   // fallback
      ]
    }
  });

export default i18n;