import { ApiResponse } from "../../types";
import { WebServerBase } from '../base/WebServerBase';
import { CalculateCost, ICalculateCost, CalculateCostInfo, IGetRoutingInfo, IRoutingParams } from "./types/CalculateCost";


const controllerName = 'Customer';


export class WebServerCustomer {

    private _webServer: WebServerBase;

    
    constructor(webServer: WebServerBase) {
        this._webServer = webServer;
    }

    public async CalculateCost(parameter: CalculateCostInfo): Promise<ApiResponse<ICalculateCost[]>> {
        const response = await this._webServer.fetchPOST<ICalculateCost[]>(controllerName, 'CalculateCost', parameter)

        if (response.Result) {
            response.Result = response.Result.map(cost => new CalculateCost(cost));
        };

        return response as ApiResponse<ICalculateCost[]>;
    }

    public async GetRoutingInfo(parameter: IRoutingParams): Promise<ApiResponse<IGetRoutingInfo>> {
        const response = await this._webServer.fetchPOST<ICalculateCost[]>(controllerName, 'GetRoutingInfo', parameter)

        return response as ApiResponse<IGetRoutingInfo>;
    }
}