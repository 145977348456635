import { SendActivationKeyResult, UserType } from "../../webServer/controllers/commonWeb/types/type";

export interface RegistrationState {
    sendActivationKeyResult: SendActivationKeyResult | null,
    activationKeyResult: IActivationKeyResult | null;
    error: string | null;
    activationKeyOpen: boolean,
    currentTariffOpen: boolean,
    calcCostProcessing: boolean,
    getRouteProcessing: boolean
}

export enum RegistrationActionTypes {
    SEND_ACTIVATION_KEY = 'SEND_ACTIVATION_KEY',
    ACTIVATION_KEY = 'ACTIVATION_KEY',
    SET_USER_KEY = 'SET_USER_KEY',
    GET_PHONE = 'GET_PHONE',
    GET_ERROR = 'GET_ERROR',
    ACTIVATION_KEY_OPEN = 'ACTIVATION_KEY_OPEN',
    CURRENT_TARIFF_OPEN = 'CURRENT_TARIFF_OPEN',
    CALC_COST_PROCESSING = 'CALC_COST_PROCESSING',
    GET_ROUTE_PROCESSING = 'GET_ROUTE_PROCESSING'
}

export interface IActivationKeyResult {
    userId: {
      id: number,
      serverId: number
    },
    userKey: string,
    userLogin: string,
    userType: UserType,
    userRoles: UserRoles[],
    inactiveGroupUserRoles: UserRoles[],
    isNewUser: boolean,
    userPhone: string,
    userName: string
}

export interface UserRoles {
    role: Role,
    roleAllowed: boolean,
    groupId: number,
    status: ActivationKeyStatus
}

enum ActivationKeyStatus {
    new = 'new',
    waitingApproval = 'waitingApproval',
    roleApproved = 'roleApproved',
    roleNotApproved = 'roleNotApproved'
}

enum Role {
    unknown = 'unknown',
    adminPMS = 'adminPMS',
    taxiDrivers = 'taxiDrivers',
    mobileDispatcher = 'mobileDispatcher',
    localDispatcher = 'localDispatcher',
    groupManager = 'groupManager',
    paymaster = 'paymaster',
    sediManager = 'sediManager',
    superDriver = 'superDriver',
    siteManager = 'siteManager',
    headManager = 'headManager',
    carWasher = 'carWasher',
    farmer = 'farmer',
    geoController = 'geoController',
    geoControllingObject = 'geoControllingObject',
    addressEditor = 'addressEditor',
    seniorDispatcher = 'seniorDispatcher',
    operator = 'operator',
    logist = 'logist',
    foreman = 'foreman',
    techSupportOperator = 'techSupportOperator',
    cashier = 'cashier',
    mechanic = 'mechanic',
    nurse = 'nurse',
    appraiser = 'appraiser',
    assistantCashier = 'assistantCashier',
    salesManager = 'salesManager',
    salesDirector = 'salesDirector'
}