import { parseJsonp } from "../../utils/parseJsonp";
import TAXI_PARAMS from '../../utils/build';

const currentDate = new Date().toISOString();

export default class WebServerApi {

    _apiBase = `${TAXI_PARAMS.WEBAPI_URL}/handlers/sedi/api.ashx?`;
    _apiKey = TAXI_PARAMS.WEBSERVER_APIKEY;
    // _userKey = '4ed54e6a-cc52-42c8-9b5e-d92b57bd7260'; 
  
    async getResource(url: string) {
      const res = await fetch(`${this._apiBase}${url}`);
  
      if (!res.ok) {
        throw new Error(`Could not fetch ${url}` +
          `, received ${res.status}`)
      }
      const jsonp = await res.text();
      const obj = parseJsonp(jsonp);
      return obj;
    }

    async getInformation(lang: string) {
        const res = await this.getResource(`apikey=${this._apiKey}&lang=${lang}&q=get_tariffs`).then(data => (data.Specs));
        return res;
    }

    async getActivationKey(phone: string, lang: string) {
      const res = await this.getResource(`q=get_activation_key&usertype=Customer&apikey=${this._apiKey}&lang=${lang}&phone=${phone}`);
      return res;
    }

    async activationKey(personalKey: string, name: string | null = '?', lang: string) {
        const res = await this.getResource(`q=activation_key&usertype=customer&apikey=${this._apiKey}&lang=${lang}&name=${name}&actkey=${personalKey}`);
        return res;
    }

    async addOrder(userKey: string, phone: string, lang: string, tariff: string, cost: number, 
                  city0: string, street0: string, house0: string, object0: string, lat0: number, lon0: number, 
                  city1: string, street1: string, house1: string, object1: string, lat1: number, lon1: number) {
        const res = await this.getResource(`q=add_order&date=${currentDate}&name=?&phone=${phone}&tariff=${tariff}&cost=${cost}&ordertype=rush&apikey=${this._apiKey}
        &cashless=${false}&lang=${lang}&userkey=${userKey}
        &city0=${city0}&street0=${street0}&house0=${house0}&object0=${object0}&lat0=${lat0}&lon0=${lon0}
        &city1=${city1}&street1=${street1}&house1=${house1}&object1=${object1}&lat1=${lat1}&lon1=${lon1}`);
        return res;
    }

    async getOrders(userKey: string, lang: string) {
      const res = await this.getResource(`apikey=${this._apiKey}&lang=${lang}&q=get_orders&userkey=${userKey}`);
      return res;
    }

    async cancelOrder(orderId: number, userKey: string, lang: string) {
        const res = await this.getResource(`orderid=${orderId}&apikey=${this._apiKey}&lang=${lang}&q=cancel_order&userkey=${userKey}`);
        return res;
    }
}

export interface ISpecs {
  ID: string,
  Key: string,
  Name: string,
  IsProperty: boolean,
  Type: string,
  Cost: Cost,
  Property: Property
}

type Cost = {
    Value: number,
    Unit: string
}

type Property = {
  Key: string,
  Type: string,
  PropertyType: string,
  SequenceNumber: number,
  ID: number,
  Name: string
}