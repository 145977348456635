import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import { IonSlides, IonSlide, IonIcon, IonSpinner, IonLabel, IonItem } from "@ionic/react";
import { carSportSharp } from "ionicons/icons";
import { shortWord } from "../../utils/shortWord";
import TariffsModal from "../TariffsModal/TariffsModal";
import RegistrationAlert from "../bricks/RegistrationAlert";
import {
  ICalculateCost,
  CalculateCostInfo,
} from "../../webServer/controllers/customer/types/CalculateCost";
import "./TariffsCarousel.scss";
import { useTranslation } from "react-i18next";

type Props = ReturnType<typeof mapDispatchToProps> &
  	ReturnType<typeof mapStateToProps>

const TariffsCarousel: React.FC<Props> = (props) => {
	const { 
        cost, 
        destination, 
        tariffTitleAction,  
        activationKeyResult,
        currentTariffOpen,
        currentTariffOpenAction,
        calcCostProcessing,
        getRouteProcessing
    } = props;

    const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [basicTariffId, setBasicTariffId] = useState("");
    const [tariffCost, setTariffCost] = useState(0);
	const [regAlertOpen, setRegAlertOpen] = useState(false);
    
	const regAlertOpenClick = () => {
		setRegAlertOpen(true);
	};

	const regAlertCloseClick = () => {
		setRegAlertOpen(false);
        setShowModal(false);
	};

	const openModal = () => {
		setShowModal(!showModal);
	};

	const tariffTitle = (c: ICalculateCost) => {
        localStorage.setItem('calculateCost', JSON.stringify(c));
		if(activationKeyResult && activationKeyResult.userKey) {
			tariffTitleAction(c.tariffDescription);
			setBasicTariffId(c.basicTariffId.toString());
            setTariffCost(c.cost);

		} else {
			regAlertOpenClick();
		}
        openModal();
	};

    const openTariffHandle = useCallback(() => {
        if(activationKeyResult && activationKeyResult.userKey && currentTariffOpen) {
            const calculateCost = JSON.parse(localStorage.getItem('calculateCost') || '{}');
			tariffTitleAction(calculateCost.tariffDescription);
			setBasicTariffId(calculateCost.basicTariffId.toString());
            setTariffCost(calculateCost.cost);
            openModal();
            currentTariffOpenAction(false);
		}
    }, [currentTariffOpen]);

    useEffect(() => {
        openTariffHandle();
    }, [currentTariffOpen]);

	const slideOpts = {
		speed: 400,
		slidesPerView: 4,
		slidesPerGroup: 1,
		spaceBetween: 10,
		pagination: {
		    el: null,
		},
	};

	const TariffItem = () => {
		return (
            <div 
                className="tariffContainer">
				{cost.map((c) => (
                    <div
                        key={c.basicTariffId}
                        className="tariffBox"
                        onClick={() => tariffTitle(c)}
                    >
                        <IonIcon icon={carSportSharp} className="tariffBox__icon"></IonIcon>
                        <p className="tariffBox__title">
                            {shortWord(c.tariffDescription, 7)}
                        </p>
                        {destination && destination.lat > 0 && (
                        <div className="tariffBox__price">
                            {Math.ceil(c.cost)}
                            {c.currency}
                        </div>
                        )}
                    </div>
				))}
			</div>
		);
	};

	const Carousel = () => {
		return (
            <IonSlides 
                className="tariffCarousel" 
                pager={true} 
                options={slideOpts}>
				{cost.map((c) => (
                    <IonSlide key={c.basicTariffId}>
                        <div className="tariffBox" onClick={() => tariffTitle(c)}>
                            <IonIcon
                                icon={carSportSharp}
                                className="tariffBox__icon"
                            ></IonIcon>
                            <p className="tariffBox__title">
                                {shortWord(c.tariffDescription, 7)}
                            </p>
                            {c.calculatedDistance > 0 && (
                                <div className="tariffBox__price">
                                {Math.ceil(c.cost)}
                                {c.currency}
                                </div>
                            )}
                        </div>
                    </IonSlide>
				))}
			</IonSlides>
		);
	};

	return (
		<>  
            {
            calcCostProcessing || getRouteProcessing ? 
            
                    <IonItem class="spinner" >
                        <IonSpinner name="lines" color="primary" /> 
                        <IonLabel>{calcCostProcessing ? t("calculateCost") : getRouteProcessing ?  t("gettingRoute") : ""}</IonLabel>
                    </IonItem> : 
                cost.length > 5 ? Carousel() : TariffItem() 
            }
			<TariffsModal
				showModal={showModal}
				openModal={openModal}
				basicTariffId={basicTariffId}
                tariffCost={tariffCost}
			/>
			<RegistrationAlert
				regAlertOpen={regAlertOpen}
				regAlertCloseClick={regAlertCloseClick}
			/>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
    cost: state.map.cost,
    destination: state.map.destination,
    activationKeyResult: state.registration.activationKeyResult,
    currentTariffOpen: state.registration.currentTariffOpen,
    calcCostProcessing: state.registration.calcCostProcessing,
    getRouteProcessing: state.registration.getRouteProcessing
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    calculateCostAction: (parameter: CalculateCostInfo) =>
        dispatch(actions.map.calculateCostAction(parameter)),
    tariffTitleAction: (title: string) =>
        dispatch(actions.map.tariffTitleAction(title)),
    currentTariffOpenAction: (currentTariffOpen: boolean) => 
        dispatch(actions.registration.currentTariffOpenAction(currentTariffOpen)),
    calcCostProcessingAction: (calcCostProcessing: boolean) => 
        dispatch(actions.registration.calcCostProcessingAction(calcCostProcessing)),
    getRouteProcessingAction: (getRouteProcessing: boolean) => 
        dispatch(actions.registration.getRouteProcessingAction(getRouteProcessing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsCarousel);
