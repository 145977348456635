import React, { useState } from 'react';
import { IonModal, IonContent, IonHeader, IonButtons, IonTitle, IonToolbar, IonIcon, IonList, IonItem, IonLabel } from '@ionic/react';
import { cogSharp, informationCircle, arrowForwardCircle, chevronBack } from 'ionicons/icons';
import './AdditionallyModal.scss'
import Services from '../Services/Services';
import { InfoModal } from '../InfoModal/InfoModal';
import { useTranslation } from 'react-i18next';

type Props = {
    showModal: boolean;
    openModal: () => void;
}

const AdditionallyModal: React.FC<Props> = ({ showModal, openModal }) => {
    const { t } = useTranslation();
    const [openSetvices, setOpenServices] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
  
    const handleInfoToggle = () => {
        setInfoOpen(!infoOpen)
    }

    const toggleServices = () => {
        setOpenServices(!openSetvices);
    }

  return (
    <>
        <IonModal isOpen={showModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('additionally')}</IonTitle>
                    <IonButtons 
                        slot="start" onClick={() => openModal()}>
                        <IonIcon icon={chevronBack} size="large" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList className="modalListItem">
                    <IonItem onClick={toggleServices}>
                        <IonIcon slot="start" icon={cogSharp} size="large" className="bigIcon" />
                        <IonLabel>{t('services')}</IonLabel>
                        <IonIcon icon={arrowForwardCircle} size="large" className="arrowRight" />
                    </IonItem>
                    <IonItem onClick={handleInfoToggle}>
                        <IonIcon slot="start" icon={informationCircle} size="large" className="bigIcon" />
                        <IonLabel>{t('additionalInformation')}</IonLabel>
                        <IonIcon icon={arrowForwardCircle} size="large" className="arrowRight" />
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal>
        <Services 
            openSetvices={openSetvices} 
            toggleServices={toggleServices}/>
        <InfoModal 
            infoOpen={infoOpen}
            handleInfoToggle={handleInfoToggle} /> 
    </>
  );
};


export default AdditionallyModal;