import { combineReducers } from 'redux';
import map from './map/reducer';
import registration from './registration/reducer';


const rootReducer = combineReducers({
    map,
    registration
});


export default rootReducer;