import { StateType } from 'typesafe-actions';
import rootReducer from './root-reducer';


import * as mapActions from './map/actions';
import * as registrationActions from './registration/actions';

export { default } from './store';
export { default as rootReducer } from './root-reducer';

export const actions = {
    map: mapActions,
    registration: registrationActions,
}

export type RootState = StateType<typeof rootReducer>;
