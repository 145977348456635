import { WebServerBase, WebServerInitParams } from './controllers/base/WebServerBase';
import { WebServerCustomer } from './controllers/customer/WebServerCustomer';
import { WebServerCommonWeb } from './controllers/commonWeb/WebServerCommonWeb';


const defaultPort = 9021;


export class WebServerProxy {

    constructor(options: WebServerInitParams) {
        if (options.port === undefined){
            options.port = defaultPort
        }

        this._webServerBase = new WebServerBase(options);
    }


    // base
    private _webServerBase: WebServerBase;

    private get webServerBase(): WebServerBase {
        return this._webServerBase;
    }


    // customer
    private _webServersCustomer!: WebServerCustomer;

    get webServersCustomer(): WebServerCustomer {
        if (this._webServersCustomer === undefined) {
            this._webServersCustomer = new WebServerCustomer(this.webServerBase);
        }

        return this._webServersCustomer;
    }

    // commonWeb
    private _webServersCommonWeb!: WebServerCommonWeb;

    get webServersCommonWeb(): WebServerCommonWeb {
        if (this._webServersCommonWeb === undefined) {
            this._webServersCommonWeb = new WebServerCommonWeb(this.webServerBase);
        }

        return this._webServersCommonWeb;
    }
}
