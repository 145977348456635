export default class AddressAPI {
  // http://nom3.sedi.ru:7070/
  _apiBase = 'https://nominatim.openstreetmap.org/search?format=json&';

  async getResource(url: string) {
    const res = await fetch(`${this._apiBase}${url}`);

    if (!res.ok) {
      throw new Error(`Could not fetch ${url}` +
        `, received ${res.status}`)
    }
    return await res.json();
  }

  async getLocation(lang: string | null, lat: number, lng: number) {
    const res = await this.getResource(`accept-language=${lang}&q=${lat},${lng}&poligon=1&addressdetails=1`);
    return res;
  }
}