
import store from "../store";
import { initWebServerProxy } from "../webServer";
import TAXI_PARAMS from "./build";

export function userInitWebServerProxy() {
    const activationKeyResult = store.getState().registration.activationKeyResult;
    const licenceKey = TAXI_PARAMS.WEBSERVER_APIKEY
    if(activationKeyResult) {
        initWebServerProxy(`Basic ${activationKeyResult.userKey}`);
    } else {
        initWebServerProxy(`Basic ${licenceKey}`);
    }

}