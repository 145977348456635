import React, { Dispatch } from "react";
import { Action, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { actions, RootState } from "../../store";
import AddressAPI from "../api/addressAPI";
import { AddressCenter, IGetAddressItem } from "sedi-webserverproxy";
import { useTranslation } from "react-i18next";
import './Marker.scss';
import { routePoints } from "../../utils/routePoints";

type Props = ReturnType<typeof mapDispatchToProps> & 
    ReturnType<typeof mapStateToProps> & {
    pointCenter: AddressCenter | null;
    changePointToHandle(pointCenter: AddressCenter | null): void;
    clipMarker: boolean;
    clipMarkerHandle(clipMarker: boolean): void;
}

const Marker:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const addressAPI = new AddressAPI();
    const {
        pointCenter,
        changePointToHandle,
        setRoutePointAction,
        currentAddressAction,
        valueFromViewAction,
        selectedPoint,
        lang,
        clipMarker,
        clipMarkerHandle
    } = props;

    const changePiontClick = (center: AddressCenter) => {
        addressAPI.getLocation(lang, center.lat, center.lon).then((data) => {
            let type = "c";
            const street = data[0].address.road;
            const object =
                data[0].address.building ||
                data[0].address.leisure ||
                data[0].address.amenity;
            const house = data[0].address.house_number;
            if (street) {
                type = "s";
            }
            if (object) {
                type = "o";
            }
            if (house) {
                type = "h";
            }
            const routePiont: IGetAddressItem = {
                co: data[0].address.country,
                c: data[0].address.city,
                cid: 0,
                s: street || data[0].display_name,
                n: "",
                h: house,
                g: {
                    lat: data[0].lat,
                    lon: data[0].lon,
                },
                t: type,
                v: house || object || "",
            };
            setRoutePointAction(routePiont);
            currentAddressAction(routePiont);
            const ruResult = routePoints(routePiont);
            if(ruResult) {
                valueFromViewAction(ruResult);
            }
            changePointToHandle(null);
            clipMarkerHandle(false);
        });
    }

    if(clipMarker) {
        if(pointCenter) {
            return (
                <div 
                    className="pinIcon"
                    onClick={() => changePiontClick(pointCenter)}>
                    <div 
                        className="pinIcon__tooltip">
                        {t("changeDropPoint")} {selectedPoint === 1 ? "A" : "B"}
                    </div>
                    <span className="pinIcon__item"></span>
                </div>
            )
        } else {
            return (
                <div className="pinIcon">
                    <span className="pinIcon__item"></span>
                </div>
            )
        }
    } else {
        return <></>
    }
}

const mapStateToProps = (state: RootState) => ({
    selectedPoint: state.map.selectedPoint,
    lang: state.map.lang
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    setRoutePointAction: (route: IGetAddressItem) =>
        dispatch(actions.map.setRoutePointAction(route)),
    currentAddressAction: (currentAddress: IGetAddressItem) =>
        dispatch(actions.map.currentAddressAction(currentAddress)),
    valueFromViewAction: (valueFromView: string) =>
        dispatch(actions.map.valueFromViewAction(valueFromView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Marker);