import { jsonReplacer } from "../../utils/convert";
import { IParametersDictionary, ApiResponse, ErrorDescription, dateFormat } from "../../types";


export type WebServerInitParams = {
    serverUrl: string,
    port: number,
    webApiUrl: string,
    licenceKey: string,
    userKey?: string
}


export class WebServerBase {

    private serverUrl: string;
    private port: number
    private webApiUrl: string;
    private licenceKey: string;
    // private userKey: string;


    constructor(options: WebServerInitParams) {
        this.serverUrl = options.serverUrl;
        this.port = options.port;
        this.webApiUrl = options.webApiUrl;
        this.licenceKey = options.licenceKey;
        // this.userKey = options.userKey;
    }


    private getUri(controller: string, method: string, params: IParametersDictionary): URL {
        let url = new URL(`${this.serverUrl}:${this.port}/api/${controller}/${method}`);

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

        return url;
    }


    public async fetch<T>(controller: string, method: string, params?: IParametersDictionary): Promise<ApiResponse<T>> {
        // immutable
        params = Object.assign({}, params);
        params.licenceKey = this.licenceKey;

        const url = this.getUri(controller, method, params).toString();

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: this.getRequestHeaders()
            });

            const apiResponse = await getApiResponse<T>(response);

            return apiResponse;
        }
        catch (ex: any) {
            return getApiResponseForException<T>(ex);
        }
    }


    public async fetchPOST<T>(controller: string, method: string, body?: any, urlParams?: IParametersDictionary): Promise<ApiResponse<T>> {
        let params: IParametersDictionary = {
            licenceKey: this.licenceKey
        };

        if (urlParams) {
            Object.keys(urlParams)
                .forEach(paramName => params[paramName] = urlParams[paramName]);
        }

        const url = this.getUri(controller, method, params).toString();

        let bodyContent;
        bodyContent = body !== null && body !== undefined ? JSON.stringify(body, jsonReplacer) : '';
        //console.log(`POST DATA\n${bodyContenet}\n`);

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: bodyContent,
                headers: this.getRequestHeaders()
            });

            const apiResponse = await getApiResponse<T>(response);

            return apiResponse;
        }
        catch (ex: any) {
            return getApiResponseForException<T>(ex);
        }
    }


    private getRequestHeaders(): HeadersInit | undefined {
        return {
            'Content-Type': "application/json;charset=UTF-8"
        };
    }


    public GetServerUrl() {
        return this.serverUrl;
    }


    public GetWebApiUrl() {
        return this.webApiUrl;
    }
}


export async function getApiResponse<T>(response: Response) {
    const parsedResult = await getParsedResponse(response);
    const parsedAsErrorObject = parsedResult as ErrorDescription;
    const hasError = parsedAsErrorObject.errorCode !== undefined || parsedAsErrorObject.message !== undefined;

    let apiResponse: ApiResponse<T> = {
        Success: response.ok && hasError === false,
        HttpStatus: response.status,
        HttpStatusText: response.statusText,
        Message: parsedResult.message || parsedAsErrorObject.message || (response.ok === false ? response.statusText : undefined),
        ErrorCode: parsedAsErrorObject.errorCode
    };

    if (!response.ok) {
        return apiResponse;
    }

    apiResponse.Result = parsedResult as T;

    return apiResponse;
}


function getApiResponseForException<T>(exception: Error) {
    const apiResponse: ApiResponse<T> = {
        Success: false,
        HttpStatus: -1,
        HttpStatusText: typeof exception,
        Message: exception.message || typeof exception,
        ErrorCode: undefined
    };

    return apiResponse;
}


async function getParsedResponse(response: Response): Promise<any> {
    const textResponse = await response.text();
    //console.log(`RAW ${textResponse}\n`);

    // reviver используется, чтобы даты в формате ISO конвертировались в объект Date
    const result = textResponse === '' ? '' : JSON.parse(textResponse, dateReviver);

    return Promise.resolve(result);
}


function dateReviver(key: string, value: any) {
    if (typeof value === "string" && dateFormat.test(value)) {
        let date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

        return date;
    }

    return value;
}