import React, { Dispatch, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import i18next from 'i18next';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonButtons,
  IonContent,
  IonLabel,
} from '@ionic/react';

import './Menu.scss';
import { actions } from '../../store';
import { localStorageGetItem } from '../../utils/storage';
import enIcon from '../../icons/united-states.svg';
import deIcon from '../../icons/germany.svg';
import ruIcon from '../../icons/russia.svg';
import heIcon from '../../icons/israel.svg'
import TAXI_PARAMS from '../../utils/build';

var packageJson = require('../../../package.json');

interface MenuProps extends RouteComponentProps {
  selectedPage: string;
}

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

type Props = ReturnType<typeof mapDispatchToProps> &
  MenuProps;

const Menu: React.FunctionComponent<Props> = ({
    langAction,
}) => {
    const currentLang = () => {
        const lang = localStorageGetItem('i18nextLng');
        if (lang !== null) {
            return lang;
        } else {
            return TAXI_PARAMS.DEFAULT_LANG;
        }
    };

    const [language, setLanguage] = useState(currentLang);

    function handleClick(e: CustomEvent) {
        i18next.changeLanguage(e.detail.value);
        setLanguage(e.detail.value);
    }

    useEffect(() => {
        if (language) {
            if (language === 'he') {
                document.documentElement.dir = "rtl";
              
            } else {
                document.documentElement.dir = "ltr";
            }
            langAction(language);
        }
    }, [langAction, language]);

    return (
        <IonMenu contentId="main">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{TAXI_PARAMS.DEFAULT_TITLE}</IonTitle>
                    <IonButtons slot="end">
                        {language && 
                            <img className="flagIcon" src={flagIcons(language)} />
                        }
                        <IonSelect
                        className="langSelect"
                        value={language}
                        placeholder="Select One"
                        onIonChange={(e) => handleClick(e)}
                        >
                            <IonSelectOption value="en">en</IonSelectOption>
                            <IonSelectOption value="de">de</IonSelectOption>
                            <IonSelectOption value="ru">ru</IonSelectOption>
                            <IonSelectOption value="he">he</IonSelectOption>
                        </IonSelect>
                    </IonButtons>
                  </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonLabel class='sidebar-version'>v {packageJson.version}</IonLabel>
            </IonContent>
        </IonMenu>
    );
};

const flagIcons = (language: string | null) => {
  switch (language) {
    case 'ru':
      return ruIcon;
    case 'de':
      return deIcon;
    case 'he':
    return heIcon;
    default:
      return enIcon;
  }
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  langAction: (lang: string) => dispatch(actions.map.langAction(lang)),
});

export default connect(null, mapDispatchToProps)(withRouter(Menu));
