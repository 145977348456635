import Menu from '../Menu/Menu';
import Page from '../../pages/Page';
import React, { Component } from 'react';
import { IonAlert, IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import store from '../../store';
import { Provider } from 'react-redux';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.scss';
import './App.scss';
import Authorization from '../Authorization/Authorization';

type Props = {

}

type State = {
    selectedPage: string,
    showInstallPWA: boolean
}
class App extends Component<Props, State> {
    deferredPrompt: any = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedPage: '',
            showInstallPWA: false
        };
    }

    beforeinstallpromptHandle() {
        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent the mini-infobar from appearing on mobile
            event.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = event;
            // Update UI notify the user they can install the PWA
            this.setState({
                showInstallPWA: true
            });
            // Optionally, send analytics event that PWA install promo was shown.
        });
    };

    async buttonInstall() {
        if(this.deferredPrompt) {
            // Show the install prompt
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            const { outcome } = await this.deferredPrompt.userChoice;
            // Optionally, send analytics event with outcome of user choice
            console.log(`User response to the install prompt: ${outcome}`);
            // We've used the prompt, and can't use it again, throw it away
            this.deferredPrompt = null;
        }
        // Hide the app provided install promotion
        this.setState({
            showInstallPWA: false
        });
    }

    componentDidMount() {
        this.beforeinstallpromptHandle();
    }

    componentDidUnmount() {
        this.beforeinstallpromptHandle();
    }

    render() {
        return (
            <Provider store={store}>
                <IonApp>
                    <IonReactRouter>
                    <IonSplitPane contentId="main">
                        <Menu selectedPage={this.state.selectedPage} />
                        <IonRouterOutlet id="main">
                        <Route
                            path="/page/:name"
                            render={(props) => {
                            this.setState({
                                selectedPage: props.match.params.name
                            });
                            return <Page {...props} />;
                            }}
                            exact={true}
                        />
                        <Route path="/" component={Page} exact={true} />
                        <Route path="/authorization" component={Authorization} />
                        </IonRouterOutlet>
                    </IonSplitPane>
                    </IonReactRouter>
                </IonApp>
                <IonAlert
                    isOpen={this.state.showInstallPWA}
                    onDidDismiss={() => this.setState({
                        showInstallPWA: false
                    })}
                    header={'Save Apps'}
                    message={'Save apps on your device?'}
                    buttons={[
                        {
                            text: "Cancel",
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => this.setState({
                                showInstallPWA: false
                            }),
                        },
                        {
                            text: 'Ok',
                            cssClass: 'installPWA',
                            handler: () => this.buttonInstall()
                        }
                    ]}
                />
            </Provider>
        )
    }
};

export default App;
