import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

interface Setting {
  enableHighAccuracy: boolean,
  timeout: number,
  maximumAge: number,
}

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export const getCurrentLocation = (settings: Setting = defaultSettings) => new Promise(async (res, rej) => {
  try {
    const coords = await Geolocation.getCurrentPosition(settings)
    res(coords)
  } catch (error) {
    rej(error)
  }
})

export const watchPosition = async (callback: () => void, settings: Setting = defaultSettings) => {
  await Geolocation.watchPosition(settings, callback);
};