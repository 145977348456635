import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonIcon } from '@ionic/react';
import { settingsSharp } from 'ionicons/icons';
import AdditionallyModal from '../AdditionallyModal/AdditionallyModal';
import { useTranslation } from 'react-i18next';

const Additionally:React.FC = () => {
    const { t } = useTranslation();
    const [orderChecked, setOrderChecked] = useState(true);
    const [showModal, setShowModal] = useState(false);
  
    const openModal = () => {
        setShowModal(!showModal)
    }

    const handleOrder = () => {
        setOrderChecked(!orderChecked)
    }
    
    return (
        <>
            <IonGrid className="settingContainer">
                <IonRow>
                    <IonCol>
                        <div 
                            className="order"
                            onClick={handleOrder}>
                            <IonCheckbox 
                                slot="start" 
                                color="danger" 
                                value="Срочный заказ" 
                                checked={orderChecked} />
                            <IonLabel>{t('rushOrder')}</IonLabel>
                        </div>
                    </IonCol>
                    <IonCol>
                        <div className="additionally" onClick={openModal}>
                            <IonIcon icon={settingsSharp} />
                            <IonLabel>{t('additionally')}</IonLabel>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <AdditionallyModal
                showModal={showModal}
                openModal={openModal} />
        </>
    )
}

export default Additionally