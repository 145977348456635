/**
 * детали ошибки, например
 * {"errorCode":"unknown","message":"Не указаны ни телефоны, ни e-mail клинета "}
 */
export type ErrorDescription = {
    errorCode: string,
    message: string
}


export type WebApiResponse = {
    Success: boolean,
    Message?: string,
    ErrorCode?: string
}


export type ApiResponse<T> = WebApiResponse & {
    HttpStatus: number,
    HttpStatusText: string,
    Result?: T
}


export interface IParametersDictionary {
    [index: string]: string;
}


export const dateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;