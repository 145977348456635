/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useState, useRef } from "react";
import { Action, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IonItem,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonList,
  IonSpinner,
  IonAlert,
} from "@ionic/react";
import {
  locate,
  trash,
  timeSharp,
  carSportSharp,
  chevronDown,
  location,
  time,
} from "ionicons/icons";
import Moment from "react-moment";
import "moment/locale/ru";
import "moment/locale/de";
import "moment/locale/en-sg";
import { useTranslation } from "react-i18next";

import "./BottomContainer.scss";
import { RootState, actions } from "../../store";
import TariffsCarousel from "../TariffsCarousel/TariffsCarousel";
import {
  CalculateCostInfo,
  IRoutingParams,
} from "../../webServer/controllers/customer/types/CalculateCost";
import Additionally from "../Additionally/Additionally";
import WebServerApi from "../api/WebServerApi";
import SearchAdress from "../SearchAdress/SearchAdress";
import TAXI_PARAMS from "../../utils/build";
import { AddressCenter } from "sedi-webserverproxy";
import { IRoute } from "../../store/map/types";
import { useHistory } from "react-router";
import { routePoints } from "../../utils/routePoints";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    handleLocationClick: () => void;
  };

const BottomContainer: React.FC<Props> = (props) => {
  const {
    handleLocationClick,
    calculateCostAction,
    footerHeightAction,
    route,
    selectedPointAction,
    valueFromViewAction,
    successAddOrder,
    successAddOrderAction,
    getOrignAction,
    getDestinationAction,
    routeResetAction,
    routeReset,
    serviceIsChecked,
    lang,
    carInWayAction,
    center,
    activationKeyResult,
    routingInfo,
    getRoutingInfoAction,
    getRouteAction,
    activationKeyOpenAction,
    currentTariffOpenAction,
    calcCostProcessingAction
  } = props;

  let history = useHistory();

  const webServerApi = new WebServerApi();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState({
    id: 0,
    status: {
      id: "",
      name: "",
    },
    date: Date,
    tariff: "",
    paymentManner: "",
    routeForm: "",
    routeTo: "",
    driver: {
      name: "",
      number: "",
      carModel: "",
      color: "",
    },
  });

  const handleSearchToggle = (selectedPoint: number, selectedAddress: any) => {
    valueFromViewAction(selectedAddress);
    selectedPointAction(selectedPoint);
    setSearchOpen(true);
  };

  const closeSearchClick = () => {
    setSearchOpen(false);
  };

  useEffect(() => {
    let parameter: CalculateCostInfo;
    if (route.point1 && route.point2 && 
      routingInfo && 
      routingInfo.from?.latitude === route.point1.g.lat && routingInfo.from.longitude === route.point1.g.lon && 
      routingInfo.to?.latitude === route.point2.g.lat && routingInfo.to.longitude === route.point2.g.lon) {
      parameter = {
        licenseKey: TAXI_PARAMS.WEBSERVER_APIKEY!,
        userKey: activationKeyResult ? activationKeyResult.userKey : undefined,
        orderTime: new Date(),
        totalDuration: routingInfo.totalDuration,
        totalDistance: routingInfo.totalDistance,
        specialServices: serviceIsChecked,
        isCashless: true,
        routePoints: [
          {
            latitude: route.point1.g.lat > 0 ? route.point1.g.lat : center.lat,
            longitude: route.point1.g.lon > 0 ? route.point1.g.lon : center.lon,
          },
          ...routingInfo.routePoints,
          {
            latitude: route.point2.g.lat,
            longitude: route.point2.g.lon,
          },
        ],
      };
    } else {
      if (route.point1 && !route.point2) {
        parameter = {
          licenseKey: TAXI_PARAMS.WEBSERVER_APIKEY!,
          routePoints: [
            {
              latitude:
                route.point1.g.lat > 0 ? route.point1.g.lat : center.lat,
              longitude:
                route.point1.g.lon > 0 ? route.point1.g.lon : center.lon,
            },
          ],
        };
      } else {
        return;
      }
    }
    calcCostProcessingAction(true);
    calculateCostAction(parameter).then((data) => {
      calcCostProcessingAction(false);
    });
  }, [routingInfo, serviceIsChecked, route.point1, route.point2]);

  const getOrdersData = (data: any) => {
    if (data.Success) {
        if (data.Orders[0]) {
            const {
            ID,
            Status,
            Date,
            Tariff,
            PaymentManner,
            Route,
            Driver,
            } = data.Orders[0];

            const carModel =
            Driver?.Car?.Props?.Properties?.find(
                (item: any) => item.Key === "CarModel"
            )?.Value.Name || "Not specified";

            const color =
            Driver.Car?.Props?.Properties?.find(
                (item: any) => item.Key === "Color"
            )?.Value.Name || "Not specified";

            const routeForm = `${Route.Points[0].StreetName} ${
                Route.Points[0].HouseNumber ? Route.Points[0].HouseNumber : (Route.Points[0].ObjectName ? Route.Points[0].ObjectName : "")
            }`;

            const routeTo = Route.Points[1]
            ? `${Route.Points[1].StreetName} ${
                Route.Points[1].HouseNumber ? Route.Points[1].HouseNumber : (Route.Points[1].ObjectName ? Route.Points[1].ObjectName : "")
                }`
            : "";

            setOrders({
            id: ID,
            status: {
                id: Status.ID,
                name: Status.Name,
            },
            date: Date,
            tariff: Tariff.Name,
            paymentManner: PaymentManner,
            routeForm: routeForm,
            routeTo: routeTo,
            driver: {
                name: Driver.Name,
                number: Driver.Car?.Number,
                carModel,
                color,
            },
            });

            const routeFrom = {
            lat: Route.Points[0].GeoPoint.Lat,
            lon: Route.Points[0].GeoPoint.Lon,
            };
            getOrignAction(routeFrom);

            if (Driver.hasOwnProperty("Geo")) {
            const routeTo = {
                lat: Driver.Geo.Lat,
                lon: Driver.Geo.Lon,
            };
            getDestinationAction(routeTo);
            }
        }
        }
  };

  useEffect(() => {
    if (activationKeyResult) {
      webServerApi.getOrders(activationKeyResult.userKey, lang).then((data) => {
        getOrdersData(data);

        if (data.Success) {
          if (data.Orders[0]) {
            successAddOrderAction(true);
            if (data.Orders[0].Status.ID === "inway") {
              carInWayAction(true);
            } else {
              carInWayAction(false);
            }
          } else {
                if(history.location.pathname === '/authorization') {
                    history.push('/');
                    activationKeyOpenAction(false);
                    currentTariffOpenAction(true);
                } else {
                    routeResetAction(!routeReset);
                }
          }
        }
      });
    }
  }, [successAddOrder, activationKeyResult]);

  useEffect(() => {
    if (activationKeyResult) {
      const timer = setInterval(() => {
        webServerApi
            .getOrders(activationKeyResult.userKey, lang)
            .then((data) => {
                getOrdersData(data);
                if (data.Success) {
                if (data.Orders[0]) {
                    if (data.Orders[0].Status.ID === "inway") {
                    carInWayAction(true);
                    } else {
                    carInWayAction(false);
                    }
                }
                if (data.Orders.length === 0) {
                    successAddOrderAction(false);
                    clearInterval(timer);
                    setLoader(false);
                }
                } else {
                successAddOrderAction(false);
                clearInterval(timer);
                }
            });
        }, 5000);
      return () => clearInterval(timer);
    }
  });

  const getStatuses = () => {
    if (orders.status.id === "search") {
      return (
        <>
          <IonSpinner slot="start" name="lines" color="primary"></IonSpinner>

          <ul className="driverRow">
            <li>{orders.status.name}</li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <IonIcon icon={carSportSharp} slot="start"></IonIcon>
          <ul className="driverRow">
            <li>
              {orders.driver.number}, {orders.driver.carModel},{" "}
              {orders.driver.color}, {orders.driver.name}
            </li>
          </ul>
        </>
      );
    }
  };

  useEffect(() => {
    footerHeightAction(ref.current!.clientHeight);
  });

  const pointAB = () => {
    if (route.point1 && route.point2) {
      const pointA: AddressCenter = {
        lat: route.point1.g.lat,
        lon: route.point1.g.lon,
      };

      const pointB: AddressCenter = {
        lat: route.point2.g.lat,
        lon: route.point2.g.lon,
      };

      if (
        route.point1.g.lat > 0 ||
        route.point1.g.lon ||
        route.point2.g.lat > 0 ||
        route.point2.g.lon
      ) {
        getOrignAction(pointA);
        return getDestinationAction(pointB);
      }
    }
  };

  useEffect(() => {
    pointAB();
  }, [route.point1, route.point2]);

  return (
    <div className="ionPage" ref={ref}>
      <IonFabButton
        color="danger"
        size="small"
        onClick={handleLocationClick}
        className="ionFabButton"
      >
        <IonIcon icon={locate} />
      </IonFabButton>

      {routingInfo && routingInfo.totalDistance !== 0 && (
        <div className="legsInfo">
          <div className="legsInfo__item">
            <IonIcon icon={location} />
            <span>{routingInfo.totalDistance} km</span>
          </div>

          <div className="legsInfo__item">
            <IonIcon icon={time} />
            <span>{routingInfo.totalDuration} min</span>
          </div>
        </div>
      )}

      {successAddOrder ? (
        <IonList className="orderList">
          <div className="orderDelete" onClick={() => setShowAlert(true)}>
            {loader ? (
              <IonSpinner
                slot="start"
                name="lines"
                color="primary"
              ></IonSpinner>
            ) : (
              <IonIcon icon={trash} slot="start"></IonIcon>
            )}

            <IonLabel>{orders.status.name}</IonLabel>
            <IonIcon className="down" icon={chevronDown}></IonIcon>
          </div>

          <div className="getOrders">
            <div className="getOrders__item">
              <span className="inputIcon">A</span>
              <IonLabel>{orders.routeForm}</IonLabel>
            </div>

            {orders.routeTo !== "" && (
              <div className="getOrders__item">
                <span className="inputIcon">B</span>
                <IonLabel>{orders.routeTo}</IonLabel>
              </div>
            )}

            <div className="getOrders__item">
              <IonIcon icon={timeSharp} slot="start"></IonIcon>
              <IonLabel dir="ltr">
                <Moment format="DD MMMM HH:mm" locale={lang ? lang : "en"}>
                  {orders.date.toString()}
                </Moment>
              </IonLabel>
              <IonLabel>ID: #{orders.id}</IonLabel>
            </div>
          </div>

          <div className="orderDriver">{getStatuses()}</div>
        </IonList>
      ) : (
        <>
          <IonItem
            onClick={() => handleSearchToggle(1, routePoints(route.point1))}
          >
            <span className="inputIcon">A</span>
            <IonLabel>{routePoints(route.point1) || t("textField.fromWhere")}</IonLabel>
            {/* <IonInput value={point1} placeholder={t('textField.fromWhere')} /> */}
          </IonItem>
          <IonItem
            onClick={() => handleSearchToggle(2, routePoints(route.point2))}
          >
            <span className="inputIcon">B</span>
            <IonLabel>{routePoints(route.point2) || t("textField.where")}</IonLabel>
            {/* <IonInput placeholder={t('textField.where')} value={point2} /> */}
          </IonItem>

          <Additionally />
          <TariffsCarousel />
        </>
      )}

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={"ID: #" + orders.id}
        message={t("deleteText")}
        buttons={[
          {
            text: `${t("no")}`,
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: `${t("yes")}`,
            handler: () => {
              if (activationKeyResult) {
                webServerApi
                  .cancelOrder(orders.id, activationKeyResult.userKey, lang)
                  .then((data) => {
                    setLoader(true);
                    getRoutingInfoAction(null);
                    getRouteAction({
                      point1: null,
                      point2: null,
                    });
                    valueFromViewAction("");
                  });
              }
            },
          },
        ]}
      />

      <SearchAdress
        searchOpen={searchOpen}
        handleSearchToggle={closeSearchClick}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  valueFromView: state.map.valueFromView,
  cost: state.map.cost,
  center: state.map.center,
  route: state.map.route,
  routingInfo: state.map.routingInfo,
  successAddOrder: state.map.successAddOrder,
  destination: state.map.destination,
  routeReset: state.map.routeReset,
  serviceIsChecked: state.map.serviceIsChecked,
  lang: state.map.lang,
  activationKeyResult: state.registration.activationKeyResult
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    valueFromViewAction: (valueFromView: string) =>
        dispatch(actions.map.valueFromViewAction(valueFromView)),
    calculateCostAction: (parameter: CalculateCostInfo) =>
        dispatch(actions.map.calculateCostAction(parameter)),
    footerHeightAction: (footerHeight: number) =>
        dispatch(actions.map.footerHeightAction(footerHeight)),
    selectedPointAction: (selectedPoint: number) =>
        dispatch(actions.map.selectedPointAction(selectedPoint)),
    setPointAutocomplateAction: (changePointAutocomplate: boolean) =>
        dispatch(actions.map.setPointAutocomplateAction(changePointAutocomplate)),
    successAddOrderAction: (successAddOrder: boolean) =>
        dispatch(actions.map.successAddOrderAction(successAddOrder)),
    getOrignAction: (orign: AddressCenter) =>
        dispatch(actions.map.getOrignAction(orign)),
    getDestinationAction: (destination: AddressCenter) =>
        dispatch(actions.map.getDestinationAction(destination)),
    routeResetAction: (routeReset: boolean) =>
        dispatch(actions.map.routeResetAction(routeReset)),
    getRoutingInfoAction: (parameter: IRoutingParams | null) =>
        dispatch(actions.map.getRoutingInfoAction(parameter)),
    carInWayAction: (carInWay: boolean) =>
        dispatch(actions.map.carInWayAction(carInWay)),
    getRouteAction: (route: IRoute) =>
        dispatch(actions.map.getRouteAction(route)),
    activationKeyOpenAction: (activationKeyOpen: boolean) => 
        dispatch(actions.registration.activationKeyOpenAction(activationKeyOpen)),
    currentTariffOpenAction: (currentTariffOpen: boolean) => 
        dispatch(actions.registration.currentTariffOpenAction(currentTariffOpen)),
    calcCostProcessingAction: (calcCostProcessing: boolean) =>
        dispatch(actions.registration.calcCostProcessingAction(calcCostProcessing))
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomContainer);
