import { ActionCreator, Action } from 'redux';
import { IRoute } from './types';
import { getWebServerProxy } from '../../webServer';
import { CalculateCostInfo, IRoutingParams, RoutePoints } from '../../webServer/controllers/customer/types/CalculateCost';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ISpecs } from '../../components/api/WebServerApi';
import { MapActionTypes } from "./types";
import {
    IAddressCenterAction, 
    ICalculateCostAction, 
    ICarInWay, 
    ICurrentAddress, 
    IFooterHeightAction, 
    IGetDestination, 
    IGetOrign,
    IGetRouteAction, 
    IGetSelectedPoint, 
    ILang, 
    IRouteReset, 
    IServicesAction, 
    IsetPointAutocomplate, 
    ISetRoutePoint, 
    ISetRoutePoints,
    ISetvicesIsChecked, 
    ISuccessAddOrder, 
    ITariffTitleAction,  
    IValueFromView,
    IGetRoutingInfoAction,
    IAddressInfo,
    IAddressMethod
} from './interface';
import { AddressCenter, IGetAddressItem } from 'sedi-webserverproxy';
import { userInitWebServerProxy } from '../../utils/initWebServerProxy';

export type MapActions =
    | IAddressCenterAction
    | ICalculateCostAction
    | ITariffTitleAction
    | IServicesAction
    | IFooterHeightAction
    | IValueFromView
    | IGetRouteAction
    | IGetSelectedPoint
    | ISetRoutePoint
    | IsetPointAutocomplate
    | ISuccessAddOrder
    | IGetOrign
    | IGetDestination
    | IRouteReset
    | ISetvicesIsChecked
    | ILang
    | ICarInWay
    | ISetRoutePoints
    | ICurrentAddress
    | IGetRoutingInfoAction
    | IAddressInfo
    | IAddressMethod;

export const carInWayAction: ActionCreator<ICarInWay> = (carInWay: boolean) => {
    return {
        type: MapActionTypes.CAR_IN_WAY,
        carInWay
    }
}
export const langAction: ActionCreator<ILang> = (lang: string) => {
    return {
        type: MapActionTypes.LANG,
        lang
    }
}
export const serviceIsCheckedAction: ActionCreator<ISetvicesIsChecked> = (serviceIsChecked: string[]) => {
    return {
        type: MapActionTypes.SERVICE_IS_CHECKED,
        serviceIsChecked
    }
}
export const routeResetAction: ActionCreator<IRouteReset> = (routeReset: boolean) => {
    return {
        type: MapActionTypes.ROUTE_RESET,
        routeReset
    }
}
export const getOrignAction: ActionCreator<IGetOrign> = (origin: AddressCenter) => {
    return {
        type: MapActionTypes.GET_ORIGN,
        origin
    }
}

export const getDestinationAction: ActionCreator<IGetDestination> = (destination: AddressCenter) => {
    return {
        type: MapActionTypes.GET_DESTINATION,
        destination
    }
}

export const successAddOrderAction: ActionCreator<ISuccessAddOrder> = (successAddOrder: boolean) => {
    return {
        type: MapActionTypes.SUCCES_ADD_ORDER,
        successAddOrder
    }
}

export const selectedPointAction: ActionCreator<IGetSelectedPoint> = (selectedPoint: number) => {
    return {
        type: MapActionTypes.GET_SELECTED_POINT,
        selectedPoint
    }
}
export const getRouteAction: ActionCreator<IGetRouteAction> = (route: IRoute) => {
    return {
        type: MapActionTypes.GET_ROUTE,
        route
    }
}

export const setRoutePointAction: ActionCreator<ISetRoutePoint> = (routePoint: IGetAddressItem) => {
    return {
        type: MapActionTypes.SET_ROUTE_POINT,
        routePoint
    }
}

export const addressCenterAction: ActionCreator<IAddressCenterAction> = (center: AddressCenter) => {
    return {
        type: MapActionTypes.ADDRESS_CENTER,
        center
    }
}

export const setRoutePoints: ActionCreator<ISetRoutePoints> = (points: RoutePoints[]) => {
    return {
        type: MapActionTypes.SET_ROUTE_POINTS,
        points
    }
}

export const currentAddressAction: ActionCreator<ICurrentAddress> = (currentAddress: IGetAddressItem | null) => {
    return {
        type: MapActionTypes.CURRENT_ADDRESS,
        currentAddress
    }
}

export const addressInfoAction: ActionCreator<IAddressInfo> = (addressInfo: IGetAddressItem[]) => {
    return {
        type: MapActionTypes.ADDRESS_INFO,
        addressInfo
    }
}

export const calculateCostAction: ActionCreator<ThunkAction<Promise<any>, any, string, ICalculateCostAction>> =
    (parameter: CalculateCostInfo) => {
        return async (dispatch: ThunkDispatch<any, string, Action<any>>) => {
            try {
                userInitWebServerProxy();
                const wsProxy = getWebServerProxy();
                
                const result = await wsProxy.webServersCustomer.CalculateCost(parameter);
                if (result.Success === false) {
                    throw new Error(`Search error ${result.Message}`);
                }

                const successAction: ICalculateCostAction = {
                    type: MapActionTypes.CALCULATE_COST,
                    cost: result.Result!
                };
                dispatch(successAction);

            }
            catch (e) {
                console.log(`Ошибка: ${e}`);
            }
        }
    }

export const getRoutingInfoAction: ActionCreator<ThunkAction<Promise<any>, any, string, IGetRoutingInfoAction>> =
    (parameter: IRoutingParams | null) => {
    return async (dispatch: ThunkDispatch<any, string, Action<any>>) => {
        if(parameter) {
            try {
                const wsProxy = getWebServerProxy();
                const result = await wsProxy.webServersCustomer.GetRoutingInfo(parameter);
                console.log('result:', result);
                if (result.Success === false) {
                    throw new Error(`Search error ${result.Message}`);
                }
    
                var ri = result.Result!;
                ri.from = parameter.routePoints[0]
                ri.to = parameter.routePoints.length > 1 ? parameter.routePoints[1] : null

                const successAction: IGetRoutingInfoAction = {
                    type: MapActionTypes.GET_ROUTING_INFO,
                    routingInfo: result.Result!
                };
    
                dispatch(successAction);
    
            }
            catch (e) {
                console.log(`Ошибка: ${e}`);
            }
        } else {
            const successAction: IGetRoutingInfoAction = {
                type: MapActionTypes.GET_ROUTING_INFO,
                routingInfo: null
            };

            dispatch(successAction);
        }
    }
}

export const tariffTitleAction: ActionCreator<ITariffTitleAction> = (tariffTitle: string) => {
    return {
        type: MapActionTypes.TARIFF_TITLE,
        tariffTitle
    }
}

export const servicesAction: ActionCreator<IServicesAction> = (services: ISpecs[]) => {
    return {
        type: MapActionTypes.GET_SERVICES,
        services
    }
}

export const footerHeightAction: ActionCreator<IFooterHeightAction> = (footerHeight: number) => {
    return {
        type: MapActionTypes.GET_FOOTER_HEIGHT,
        footerHeight
    }
}

export const valueFromViewAction: ActionCreator<IValueFromView> = (valueFromView: string) => {
    return {
        type: MapActionTypes.GET_VALUE_FROM_VIEW,
        valueFromView
    }
}

export const setPointAutocomplateAction: ActionCreator<IsetPointAutocomplate> = (changePointAutocomplate: boolean) => {
    return {
        type: MapActionTypes.SET_POINT_AUTOCOMPLATE,
        changePointAutocomplate
    }
}

export const addressMethodAction: ActionCreator<IAddressMethod> = (addressMethod: string) => {
    return {
        type: MapActionTypes.ADDRESS_METOD,
        addressMethod
    }
}