import React, { Dispatch, Component } from "react";
import { Action, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import GoogleMapReact from "google-map-react";
import { RootState, actions } from "../../store";
import Marker from "../Marker/Marker";
import AddressAPI from "../api/addressAPI";
import BottomContainer from "../BottomContainer/BottomContainer";
import TAXI_PARAMS from "../../utils/build";
import { getCurrentLocation } from "../bricks/locationUtils";
import {
    IRoutingParams,
    RoutePoints,
} from "../../webServer/controllers/customer/types/CalculateCost";
import icons from "./icons";
import { AddressCenter, IGetAddressItem } from "sedi-webserverproxy";
import "./Map.scss";
import { routePoints } from "../../utils/routePoints";
import i18next from "i18next";

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

const directionsRenderer = new google.maps.DirectionsRenderer({
    suppressMarkers: true,
});

let flightPath: google.maps.Polyline;

class Map extends Component<Props, any> {
    addressAPI = new AddressAPI();
    map: any;
    markers: google.maps.Marker[] = [];

    static defaultProps = {
        center: {
            lat: Number(TAXI_PARAMS.LAT || 55.755825),
            lon: Number(TAXI_PARAMS.LNG || 37.617298),
        },
            zoom: 16,
            options: {
            maxZoom: 19,
            fullscreenControl: false,
            zoomControl: false,
        },
    };

    constructor(props: Props) {
        super(props);
        this.map = React.createRef();
        this.state = {
            mapOptions: {
                zoom: Map.defaultProps.zoom,
            },
            routeLegs: {
                text: "",
                value: "",
            },
            pointCenter: null,
            clipMarker: true,
        };
    }

    makeMarker(position: google.maps.LatLng, iconUrl: string, map: any, selectedPoint: number) {
        const { selectedPointAction } = this.props;

        // const infowindow = new google.maps.InfoWindow({
        //     content: `Изменить точку ${selectedPoint === 1 ? 'A' : 'B'}`,
        // });

        const marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: {
                url: iconUrl,
                rotation: 90,
            },
            label: {
                text: `${i18next.t("changeDropPoint")} ${selectedPoint === 1 ? "A" : "B"}`,
                fontSize: "13px",
                color: "#444",
            }
        });

        // infowindow.open(map, marker);
        this.setState({
            clipMarker: false
        });
        marker.addListener("click", () => {
            if(selectedPoint === 1) {
                selectedPointAction(1);
            } else {
                selectedPointAction(2);
            }
            this.setState({
                clipMarker: true
            });
            
            if(this.state.pointCenter) {
                this.setState({
                    pointCenter: marker.getPosition() as google.maps.LatLng,
                });
            }
            map.setCenter(marker.getPosition() as google.maps.LatLng);
        });
        this.markers.push(marker);
    }

    deleteMarkers() {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
    }

    getRoutingInfo() {
        const {
            origin,
            destination,
            getRoutingInfoAction,
            getRouteProcessingAction
        } = this.props;

        if (directionsRenderer !== null) {
            directionsRenderer.setMap(null);
            this.deleteMarkers();
        }

        if (origin && destination) {
            const routeInfoParams = {
                orderTime: new Date(),
                routePoints: [
                    {
                        latitude: origin.lat,
                        longitude: origin.lon,
                    },
                    {
                        latitude: destination.lat,
                        longitude: destination.lon,
                    },
                ],
            }
            getRouteProcessingAction(true);
            getRoutingInfoAction(routeInfoParams).then(() => {
                getRouteProcessingAction(false);
            });
        }
    }

    fitBoundsByMarkers() {
        const { 
            origin,
            destination
        } = this.props;
        const mapRef = this.map.current.map_;

        if(origin && destination) {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(new google.maps.LatLng(
                origin.lat,
                origin.lon,
            ));
            bounds.extend(new google.maps.LatLng(
                destination.lat,
                destination.lon,
            ));
            mapRef.fitBounds(bounds);
        }
    }

    routeMap() {
        const {
            origin,
            destination,
            carInWay,
            routingInfo,
        } = this.props;

        try {
            const mapRef = this.map.current.map_;
            if(routingInfo) {
                const { routePoints } = routingInfo;
                if(origin && destination) {
                    if(flightPath) {
                        flightPath.setMap(null);
                    }
                    flightPath = new google.maps.Polyline({
                        path: [
                            new google.maps.LatLng(origin.lat, origin.lon),
                            ...routePoints.map(
                            (point: any) =>
                                new google.maps.LatLng(point.latitude, point.longitude)
                            ),
                            new google.maps.LatLng(destination.lat, destination.lon),
                        ],
                        geodesic: true,
                        strokeColor: "#FF0000",
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    });
            
                    flightPath.setMap(mapRef);
    
                    this.makeMarker(
                        new google.maps.LatLng(origin.lat, origin.lon),
                        icons.start.url,
                        mapRef,
                        1
                    );
    
                    this.makeMarker(
                        new google.maps.LatLng(destination.lat, destination.lon),
                        carInWay ? icons.car.url : icons.end.url,
                        mapRef,
                        2
                    );
    
                    this.fitBoundsByMarkers()
                }
            }
        } catch(error) {
            console.log('routingInfo error:', error);
        }
    }

    showCurrentLocation() {
        const { addressCenterAction } = this.props;
        let infoWindow = new google.maps.InfoWindow();

        getCurrentLocation({
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        }).then((result: any) => {
            const center: AddressCenter = {
                lat: result.coords.latitude,
                lon: result.coords.longitude,
            };

            infoWindow.setPosition({
                lat: result.coords.latitude,
                lng: result.coords.longitude,
            });

            if (this.map.current.map_) {
                this.map.current.map_.setCenter({
                lat: result.coords.latitude,
                lng: result.coords.longitude,
                });
            }

            addressCenterAction(center);
        });
    }

    componentDidMount() {
        this.showCurrentLocation();
    }

    componentDidUpdate(prevProps: Props) {
        const {
            valueFromViewAction,
            center,
            setRoutePointAction,
            changePointAutocomplate,
            destination,
            routeReset,
            carInWay,
            route,
            currentAddressAction,
            routingInfo,
            lang
        } = this.props;

        const { lat, lon } = this.props.center;
        // const lang = localStorageGetItem("i18nextLng");

        if (center !== prevProps.center) {
            if (this.map.current.map_) {
                this.map.current.map_.setCenter({
                    lat,
                    lng: lon,
                });
            }
        }

        if (center !== prevProps.center && changePointAutocomplate === false) {
            this.addressAPI.getLocation(lang, lat, lon).then((data) => {
                let type = "c";
                const street = data[0].address.road;
                const object =
                    data[0].address.building ||
                    data[0].address.leisure ||
                    data[0].address.amenity;
                const house = data[0].address.house_number;
                if (street) {
                    type = "s";
                }
                if (object) {
                    type = "o";
                }
                if (house) {
                    type = "h";
                }
                const routePiont: IGetAddressItem = {
                    co: data[0].address.country,
                    c: data[0].address.city,
                    cid: 0,
                    s: street || data[0].display_name,
                    n: "",
                    h: house,
                    g: {
                        lat: data[0].lat,
                        lon: data[0].lon,
                    },
                    t: type,
                    v: house || object || "",
                };
                setRoutePointAction(routePiont);
                currentAddressAction(routePiont);
                const ruResult = routePoints(routePiont);
                if(ruResult) {
                    valueFromViewAction(ruResult);
                }
            });
        }

        if (
            destination !== prevProps.destination ||
            carInWay !== prevProps.carInWay
        ) {
            this.getRoutingInfo();
        }

        if(routingInfo !== prevProps.routingInfo) {
            this.routeMap();
        }

        if (
            routeReset !== prevProps.routeReset ||
            (route.point1 && route.point1.s === "" && route.point1.v === "") ||
            (route.point2 && route.point2.s === "" && route.point2.v === "")
        ) {
            directionsRenderer.setMap(null);
            this.deleteMarkers();
            if(flightPath) {
                flightPath.setMap(null);
                return window.location.href = '/';
            }
        }
    }

    getMapBounds(lat: number, lng: number) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(new google.maps.LatLng(lat, lng));
        return bounds;
    }

    bindResizeListener(map: any, maps: any, bounds: google.maps.LatLngBounds) {
        maps.event.addDomListenerOnce(map, "idle", () => {
            maps.event.addDomListener(window, "resize", () => {
                map.fitBounds(bounds);
            });
        });
    }

    apiIsLoaded(map: any, maps: any, lat: number, lng: number) {
        // Получить границы наших мест
        const bounds = this.getMapBounds(lat, lng);
        // Привязать слушателя изменения размера
        this.bindResizeListener(map, maps, bounds);
    }

    handleLocationClick = () =>
        getCurrentLocation().then((result: any) => {
            const lat: number = result.coords.latitude;
            const lng: number = result.coords.longitude;

            const { valueFromViewAction, lang } = this.props;
            // const lang = localStorageGetItem("i18nextLng");

            const bounds = this.getMapBounds(lat, lng);
            this.map.current.map_.fitBounds(bounds);
            this.map.current.map_.setZoom(16);

            this.addressAPI.getLocation(lang, lat, lng).then((data) => {
                let type = "c";
                const street = data[0].address.road;
                const object =
                data[0].address.building ||
                data[0].address.leisure ||
                data[0].address.amenity;
                const house = data[0].address.house_number;
                if (street) {
                    type = "s";
                }
                if (object) {
                    type = "o";
                }
                if (house) {
                    type = "h";
                }
                const routePiont: IGetAddressItem = {
                    co: data[0].address.country,
                    c: data[0].address.city,
                    cid: 0,
                    s: street || data[0].display_name,
                    n: "",
                    h: house,
                    g: {
                        lat: data[0].lat,
                        lon: data[0].lon,
                    },
                    t: type,
                    v: house || object || "",
                };
                this.props.setRoutePointAction(routePiont);
                this.props.currentAddressAction(routePiont);

                const ruResult = routePoints(routePiont);
                if(ruResult) {
                    valueFromViewAction(ruResult);
                }
            });
        });

    onDragEndMap(map: any) {
        const {
            addressCenterAction,
            setPointAutocomplateAction,
            destination,
            route
        } = this.props;

        const newCenter: AddressCenter = {
            lat: map.center.lat(),
            lon: map.center.lng(),
        };

        if(route.point2) {
            this.setState({
                pointCenter: newCenter
            });
        }

        if (destination && destination.lat > 0) {
            return;
        }

        setPointAutocomplateAction(false);
        addressCenterAction(newCenter);
    }

    changePointToHandle(pointCenter: AddressCenter | null) {
        this.setState({
            pointCenter
        })
    }

    clipMarkerHandle(clipMarker: boolean) {
        this.setState({
            clipMarker
        });
    }

    render() {
        const { footerHeight } = this.props;
        const { lat, lon } = this.props.center;
        const footerBoxHeight = `calc(100vh - ${footerHeight}px)`;

        return (
            <div className="map" style={{ height: footerBoxHeight }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: TAXI_PARAMS.GOOGLE_MAPS_API_KEY || "" }}
                    defaultCenter={{ lat, lng: lon }}
                    defaultZoom={Map.defaultProps.zoom}
                    options={Map.defaultProps.options}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                        this.apiIsLoaded(map, maps, lat, lon)
                    }
                    draggable={true}
                    onDragEnd={(map) => this.onDragEndMap(map)}
                    ref={this.map}
                />
                <Marker 
                    pointCenter={this.state.pointCenter}
                    changePointToHandle={(pointTo) => this.changePointToHandle(pointTo)}
                    clipMarker={this.state.clipMarker} 
                    clipMarkerHandle={(clipMarker) => this.clipMarkerHandle(clipMarker)}/>
                <BottomContainer handleLocationClick={this.handleLocationClick} />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    center: state.map.center,
    valueFromView: state.map.valueFromView,
    footerHeight: state.map.footerHeight,
    route: state.map.route,
    changePointAutocomplate: state.map.changePointAutocomplate,
    origin: state.map.origin,
    destination: state.map.destination,
    successAddOrder: state.map.successAddOrder,
    routeReset: state.map.routeReset,
    carInWay: state.map.carInWay,
    routingInfo: state.map.routingInfo,
    lang: state.map.lang
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    valueFromViewAction: (valueFromView: string) =>
        dispatch(actions.map.valueFromViewAction(valueFromView)),
    addressCenterAction: (center: AddressCenter) =>
        dispatch(actions.map.addressCenterAction(center)),
    setRoutePointAction: (route: IGetAddressItem) =>
        dispatch(actions.map.setRoutePointAction(route)),
    setPointAutocomplateAction: (changePointAutocomplate: boolean) =>
        dispatch(actions.map.setPointAutocomplateAction(changePointAutocomplate)),
    setRoutePoints: (points: RoutePoints[]) =>
        dispatch(actions.map.setRoutePoints(points)),
    currentAddressAction: (currentAddress: IGetAddressItem) =>
        dispatch(actions.map.currentAddressAction(currentAddress)),
    getRoutingInfoAction: (parameter: IRoutingParams | null) =>
        dispatch(actions.map.getRoutingInfoAction(parameter)),
    selectedPointAction: (selectedPoint: number) =>
        dispatch(actions.map.selectedPointAction(selectedPoint)),
    getRouteProcessingAction: (getRouteProcessing: boolean) =>
        dispatch(actions.registration.getRouteProcessingAction(getRouteProcessing))
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
