import { IGetAddressItem } from "sedi-webserverproxy";

export function routePoints(point: IGetAddressItem | null) {
    if (point) {
        if (point.t === "c") {
            return point.c;
        } else if (point.t === "s") {
            if(point.s === null) {
                return point.v
            } else {
                return point.s;
            }
        } else if (point.t === "o") {
            return point.v;
        } else {
            return `${point.s}${point.v !== "" ? ", " : ""}${point.v}`;
        }
    }
};