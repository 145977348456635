export function parseJsonp(json){
    function setCharAt(str,index,chr) {
        if(index > str.length-1) return str;
        return str.substr(0,index) + chr + str.substr(index);
    }
    
    var pos = -1;
    while (true) {
      pos = json.indexOf('new Date', pos)
      var start = pos;
      if(start === -1)
        break;
    
      pos = json.indexOf(')', pos + 1);
      var end = pos;
      if(end === -1)
        break;
        
      json = setCharAt(json,start,"\"" );  
      json = setCharAt(json,end+2,"\"" );  
    } 
    
    function dateParser(key, value) {
      if (typeof value === 'string' && value.includes("new Date")) {
        return window.eval(value);
      }
      return value;
    };
    
    var o = JSON.parse(json, dateParser);
    return o;
}