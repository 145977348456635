import { ICalculateCost, IGetRoutingInfo } from "../../webServer/controllers/customer/types/CalculateCost";
import { ISpecs } from "../../components/api/WebServerApi";
import { AddressCenter, IGetAddressItem } from "sedi-webserverproxy";

export interface MapState {
    route: IRoute;
    currentAddress: IGetAddressItem | null,
    addressInfo: IGetAddressItem[],
    routingInfo: IGetRoutingInfo | null;
    selectedPoint: number;
    center: AddressCenter;
    cost: ICalculateCost[];
    tariffTitle: string;
    services: ISpecs[];
    footerHeight: number;
    valueFromView: string;
    changePointAutocomplate: boolean;
    successAddOrder: boolean;
    origin: AddressCenter | null;
    destination: AddressCenter | null;
    routeReset: boolean;
    serviceIsChecked: string[],
    lang: string,
    carInWay: boolean,
    addressMethod: string
}

export enum MapActionTypes {
    ADDRESS_CENTER = 'ADDRESS_CENTER',
    CALCULATE_COST = 'CALCULATE_COST',
    TARIFF_TITLE = 'TARIFF_TITLE',
    GET_SERVICES = 'GET_SERVICES',
    GET_FOOTER_HEIGHT = 'GET_FOOTER_HEIGHT',
    GET_VALUE_FROM_CACHE = 'GET_VALUE_FROM_CACHE',
    GET_ADDRESS_FROM_CACHE = 'GET_ADDRESS_FROM_CACHE',
    GET_VALUE_FROM_VIEW = 'GET_VALUE_FROM_VIEW',
    GET_ADDRESS_FROM_VIEW = 'GET_ADDRESS_FROM_VIEW',
    GET_PHONE = 'GET_PHONE',
    GET_ROUTE = 'GET_ROUTE',
    GET_SELECTED_POINT = 'GET_SELECTED_POINT',
    SET_ROUTE_POINT = 'SET_ROUTE_POINT',
    SET_POINT_AUTOCOMPLATE = 'SET_POINT_AUTOCOMPLATE',
    SET_USER_KEY = 'SET_USER_KEY',
    SUCCES_ADD_ORDER = 'SUCCES_ADD_ORDER',
    GET_ORIGN = 'GET_ORIGN',
    GET_DESTINATION = 'GET_DESTINATION',
    ROUTE_RESET = 'ROUTE_RESET',
    SERVICE_IS_CHECKED = 'SERVICE_IS_CHECKED',
    LANG = 'LANG',
    CAR_IN_WAY = 'CAR_IN_WAY',
    SET_ROUTE_POINTS = 'SET_ROUTE_POINTS',
    CURRENT_ADDRESS = 'CURRENT_ADDRESS',
    GET_ROUTING_INFO = "GET_ROUTING_INFO",
    ADDRESS_INFO = "ADDRESS_INFO",
    ADDRESS_METOD = "ADDRESS_METOD"
}

export type RouteLegs = {
    distanceText: string,
    durationText: string,
    totalDistance: number,
    totalDuration: number,
}
export interface RoutePoint {
    city: string,
    street: string,
    house: string,
    object: string,
    type: string,
    lat: string,
    lon: string,
}
export interface IRoute {
    point1: IGetAddressItem | null,
    point2: IGetAddressItem | null,
}
