import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import { chevronBack, saveOutline, mailOutline } from 'ionicons/icons';
import TAXI_PARAMS from '../../utils/build';
import { LoginWithActivationKeyInfo } from "../../webServer/controllers/commonWeb/types/type";
import { UserType } from "../../webServer/controllers/commonWeb/types/type";
import { useTranslation } from "react-i18next";
import { 
    IonModal, 
    IonButton, 
    IonContent, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonButtons, 
    IonIcon, 
    IonList, 
    IonItem, 
    IonLabel, 
    IonInput, 
    IonSpinner, 
    IonText 
} from '@ionic/react';
import './ActivationKeyModal.scss'

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & {
    keyModal: boolean,
    keyModalClose: () => void,
}

const ActivationKeyModal: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { 
        keyModal, 
        keyModalClose, 
        loginWithActivationKeyAction,
        error,
    } = props;
    const [keyValue, setKeyValue] = useState('');

    const confirmClick = () => {
        const parametr = {
            userType: UserType.customer,
            licenceKey: TAXI_PARAMS.WEBSERVER_APIKEY,
            activationKey: keyValue,
        }

        loginWithActivationKeyAction(parametr);
    }

    return (
        <IonModal isOpen={keyModal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('confirmations')}</IonTitle>
                    <IonButtons 
                        slot="start" onClick={() => keyModalClose()}>
                        <IonIcon icon={chevronBack} size="large" />
                    </IonButtons>
                    <IonButtons 
                        slot="end"
                        className="mr-3">
                        <IonIcon icon={mailOutline} size="large" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="confirm">
                <IonList>
                    <div className="confirm__title">{t('confirmationKey')}:</div>
                    <div className="confirm__spinner">
                        <IonSpinner name="lines" color="primary" />
                    </div>
                    <IonItem 
                        className="confirm__item">
                        <IonLabel position="stacked">{t('personalKey')}</IonLabel>
                        <IonInput value={keyValue} onIonChange={e => setKeyValue(e.detail.value!)} clearInput></IonInput>
                    </IonItem>
                    {error && 
                        <IonText color="danger" className="errorMessage">{error}</IonText>
                    }
                    <div className="confirm__btnRow">
                        <IonButton onClick={confirmClick}>
                            <IonIcon slot="start" icon={saveOutline} />
                            {t('save')}
                        </IonButton>
                    </div>
                </IonList>
            </IonContent>
        </IonModal>
    );
};

const mapStateToProps = (state: RootState) => ({
    error: state.registration.error,
    activationKeyOpen: state.registration.activationKeyOpen
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    loginWithActivationKeyAction: (parameter: LoginWithActivationKeyInfo) =>
        dispatch(actions.registration.loginWithActivationKeyAction(parameter)),
});
  
export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ActivationKeyModal);