import React, { Dispatch, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonIcon, IonContent, IonList, IonItem, IonLabel, IonCheckbox, IonButton, IonFooter } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import WebServerApi, { ISpecs } from '../api/WebServerApi';
import './Services.scss'
import { useTranslation } from 'react-i18next';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    openSetvices: boolean,
    toggleServices: () => void
}

const Services:React.FC<Props> = ({ openSetvices, toggleServices, services, servicesAction, serviceIsCheckedAction, lang }) => {
    const { t } = useTranslation();
    const webServerApi = new WebServerApi();

    useEffect(() => {
        webServerApi.getInformation(lang).then(data => {
            servicesAction(data)
        });
    }, [lang, servicesAction]);

    const serviceChecked = (event: any, service: ISpecs) => {
        service.IsProperty = event.detail.checked;
    }

    const saveServices = () => {
        const newServices = services.filter(item => item.IsProperty === true).map(item => item.Key);
        serviceIsCheckedAction(newServices);
        toggleServices();
    }
    
    return (
        <IonModal isOpen={openSetvices}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('services')}</IonTitle>
                    <IonButtons 
                        slot="start" onClick={() => toggleServices()}>
                        <IonIcon icon={chevronBack} size="large" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList className="modalListItem">
                    {services.map((item) => (
                        <IonItem key={item.ID}>
                            <IonCheckbox slot="start" color="danger" value={item.ID} name={item.ID} checked={item.IsProperty} onIonChange={e => serviceChecked(e, item)}/>
                            <IonLabel>{item.Name} (+{item.Cost.Value} {item.Cost.Unit})</IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
            <IonFooter className="servicesFooter">
                <IonButton onClick={saveServices}>{t('save')}</IonButton>
            </IonFooter>
        </IonModal>
    )
}

const mapStateToProps = (state: RootState) => ({
    services: state.map.services,
    lang: state.map.lang,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    servicesAction: (services: ISpecs[]) => dispatch(actions.map.servicesAction(services)),
    serviceIsCheckedAction: (serviceIsChecked: string[]) => dispatch(actions.map.serviceIsCheckedAction(serviceIsChecked)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services)