import TAXI_PARAMS from '../utils/build';
import { WebServerProxy } from './WebServerProxy';
import { WebServerInitParams } from './controllers/base/WebServerBase';


const { WEBSERVER_URL, WEBSERVER_APIKEY, WEBSERVER_PORT, WEBAPI_URL } = TAXI_PARAMS;


let proxy: WebServerProxy;


export const getWebServerProxy = () => {
    return proxy;
}

const baseUrl = window.location.hostname === "localhost" ? 'https://test2.sedi.ru' : WEBSERVER_URL!;

export const initWebServerProxy = (userKey: string) => {
    const options: WebServerInitParams = {
        serverUrl: baseUrl,
        licenceKey: WEBSERVER_APIKEY!, 
        webApiUrl: WEBAPI_URL!, 
        port: WEBSERVER_PORT,
        userKey: userKey
    };

    proxy = new WebServerProxy(options);
}